<template>
  <div class="ml-1 mr-1">
    <!-- Layout website -->
    <div v-if="!$vuetify.breakpoint.xs" class="img-detail d-flex flex-row">
      <div  :ref="product.barcode" :id="deliveryImage">
        <v-sheet
          elevation="1"
          max-width="500"
          :min-height="imagePaths.length > 0 ? 500 : 400"
        >
          <figure>
            <v-img
              :src="!errImage ? product.imagePath || noImage : noImage"
              v-on:error="errImage = true"
              width="400"
              height="400"
              class="mx-auto"
              ><template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    :color="bottom_background"
                  ></v-progress-circular>
                </v-row>
              </template>
              <div v-if="product.isFavorite == 1" class="like mt-5">
                <span class="ma-2">{{ $t("message.like") }}</span>
              </div>
            </v-img>
          </figure>
          <v-slide-group
            v-model="imageSelected"
            mandatory
            show-arrows
            class="mx-1 my-1"
          >
            <v-slide-item
              v-for="item in imagePaths"
              :key="item"
              v-slot="{ toggle }"
            >
              <figure>
                <v-img
                  :src="item"
                  width="90"
                  height="90"
                  @click="toggle"
                  class="ma-1 hover02"
                  ><template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        :color="bottom_background"
                      ></v-progress-circular>
                    </v-row> </template
                ></v-img>
              </figure>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </div>
      <div class="ml-7">
        <div class="d-flex flex-column">
          <span class="title-detail">{{ product.name }}</span>
          <span
            class="title-detail mt-3"
            :style="{ color: bottom_background }"
            >{{ formatMoney(product.salePrice) }}</span
          >
          <span class="mt-3 des-custom">{{ product.description }}</span>
          <div class="mt-3">
            <v-row>
              <v-col>
                <v-btn
                  fab
                  dark
                  x-small
                  color="grey"
                  @click="
                    downNumber(
                      product.barcode,
                      product.name,
                      product.salePrice,
                      product.imagePath
                    )
                  "
                  v-show="existProducts(product.barcode)"
                >
                  <v-icon> mdi-minus</v-icon>
                </v-btn>
                <span
                  v-show="existProducts(product.barcode)"
                  :id="product.barcode"
                  class="text-h6 mr-2 ml-2 number-order"
                  >{{ product.qty }}</span
                >
                <v-btn
                  fab
                  dark
                  x-small
                  :color="bottom_background"
                  :title="$t('message.addToShoppingCart')"
                  @click="
                    upNumber(
                      product.barcode,
                      product.name,
                      product.salePrice,
                      product.imagePath
                    );
                    doFly(product.barcode, $vuetify.breakpoint.xs);
                  "
                >
                  <v-icon> mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div class="mt-5">
            <div class="d-flex flex-row">
              <span class="title-share mt-1 mr-2">{{
                $t("message.share")
              }}</span>
              <div class="ml-0">
                <div class="share-network-list">
                  <ShareNetwork
                    v-for="network in networks"
                    :network="network.network"
                    :style="{ backgroundColor: 'white' }"
                    :key="network.network"
                    :url="fullPath"
                    :description="description"
                    :title="product.name"
                    @close="closeShare()"
                  >
                    <v-img height="25" width="25" :src="network.icon"></v-img>
                  </ShareNetwork>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Layout mobile -->
    <div v-if="$vuetify.breakpoint.xs" class="img-detail d-flex flex-column">
      <v-sheet
        elevation="1"
        max-width="500"
        :min-height="imagePaths.length > 0 ? '500' : '400'"
      >
        <figure>
          <v-img
            :src="!errImage ? product.imagePath || noImage : noImage"
            v-on:error="this.errImage = true"
            width="100%"
            height="400"
            ><template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-4"
                ></v-progress-circular>
              </v-row>
            </template>
            <div v-if="product.isFavorite == 1" class="like mt-5">
              <span class="ma-2">{{ $t("message.like") }}</span>
            </div>
          </v-img>
        </figure>
        <v-slide-group
          v-model="imageSelected"
          mandatory
          show-arrows
          class="mx-1 my-1"
        >
          <v-slide-item
            v-for="item in imagePaths"
            :key="item"
            v-slot="{ toggle, active }"
          >
            <figure>
              <v-img
                :src="item"
                width="84"
                height="84"
                @click="toggle"
                :class="active ? 'ma-1 hover02-selected' : 'ma-1'"
                ><template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      :color="bottom_background"
                    ></v-progress-circular>
                  </v-row> </template
              ></v-img>
            </figure>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
      <div class="ml-2 mt-2">
        <div class="d-flex flex-column">
          <span class="title-detail">{{ product.name }}</span>
          <span
            class="title-detail mt-2"
            :style="{ color: bottom_background }"
            >{{ formatMoney(product.salePrice) }}</span
          >
          <span class="mt-2 des-custom">{{ product.description }}</span>
          <div class="mt-2">
            <v-row>
              <v-col>
                <v-btn
                  fab
                  dark
                  x-small
                  color="grey"
                  @click="
                    downNumber(
                      product.barcode,
                      product.name,
                      product.salePrice,
                      product.imagePath
                    )
                  "
                  v-show="existProducts(product.barcode)"
                >
                  <v-icon> mdi-minus</v-icon>
                </v-btn>
                <span
                  v-show="existProducts(product.barcode)"
                  :id="product.barcode"
                  class="text-h6 mr-2 ml-2 number-order"
                  >{{ product.qty }}</span
                >
                <v-btn
                  fab
                  dark
                  x-small
                  :color="bottom_background"
                  :title="$t('message.addToShoppingCart')"
                  @click="
                    upNumber(
                      product.barcode,
                      product.name,
                      product.salePrice,
                      product.imagePath
                    )
                  "
                >
                  <v-icon> mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div class="mt-2">
            <div class="d-flex flex-row">
              <span class="title-share mt-1 mr-2">{{
                $t("message.share")
              }}</span>
              <div class="ml-0">
                <div class="share-network-list">
                  <ShareNetwork
                    v-for="network in networks"
                    :network="network.network"
                    :key="network.network"
                    :url="fullPath"
                    :description="description"
                    :title="product.name"
                    @close="closeShare()"
                  >
                    <v-img height="25" width="25" :src="network.icon"></v-img>
                  </ShareNetwork>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-snackbar v-model="snackbar" centered :timeout="timeout" color="green">
      <v-icon color="white">mdi-check</v-icon> {{ text }}
    </v-snackbar>
    <transition
      name="fly"
      @before-enter="beforeEnter"
      @enter="enter"
      appear
      enter-class=""
    >
      <img :src="flyingImageSrc" class="fly" v-if="showFlyingImage" />
    </transition>
  </div>
</template>
<script>
import ProductDetailMixin from "../mixins/ProductDetailMixin.js";
export default {
  mixins: [ProductDetailMixin],
};
</script>
<style scoped>
.des-custom {
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.6);
}
.share-network-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
  margin: auto;
}

a[class^="share-network-"] {
  margin: 0 10px 10px 0;
}

.fly {
  position: fixed;
  transform: scale(0.5);
  opacity: 0.5;
  z-index: 9999;
}
</style>