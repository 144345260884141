<template>
  <div>
    <!-- Layout website -->
    <v-container v-if="!$vuetify.breakpoint.xs" class="mb-5 text-font">
      <div class="d-flex justify-center mt-3" :style="{ color: text_color }">
        <h3>{{ $t("message.product") }}</h3>
      </div>
      <div class="mt-4" style="width: 100%">
        <OrderByWeb></OrderByWeb>
      </div>
      <div class="main-bg pb-2 pl-2 pr-2 mt-2">
        <ListProduct1
          :bottom_background="bottom_background"
          :shopId="shopId"
          :text_color="text_color"
        ></ListProduct1>
      </div>
      <MoveTop :backgroundColor="header_background[0]" />
    </v-container>
    <!-- Layout Mobile -->
    <v-container v-if="$vuetify.breakpoint.xs" class="mb-5 text-font">
      <div class="d-flex justify-center mt-1" :style="{ color: text_color }">
        <h3>{{ $t("message.product") }}</h3>
      </div>
      <div class="mt-2" style="width: 100%">
        <OrderByMobile></OrderByMobile>
      </div>
      <div class="main-bg pb-2 pl-2 pr-2 mt-2">
        <ListProduct1
          :bottom_background="bottom_background"
          :shopId="shopId"
        ></ListProduct1>
      </div>
    </v-container>
  </div>
</template>
<script>
import OrderByWeb from "../../components/OrderByWeb.vue";
import OrderByMobile from "../../components/OrderByMobile.vue";
import MoveTop from "../../components/MoveTop.vue";
import ListProduct1 from "../../components/ListProduct1.vue";
export default {
  name: "Home1",
  components: { OrderByWeb, MoveTop, ListProduct1, OrderByMobile },
  data: () => ({
    bottom_background: "#F8AE26",
    shopId: "",
  }),
  computed: {
    header_background() {
      return this.$store.state.products.backgroundColorLayout1;
    },
    text_color() {
      return this.$store.state.products.textColorLayout1;
    },
  },
  async beforeMount() {
    this.shopId = this.$route.params.shopId;
  },
};
</script>
<style>
@import "../../styles/home.css";
@import "../../styles/font.css";
.like {
  position: absolute;
  top: 0;
  background: #e35656;
  font-size: 0.88rem !important;
  color: white;
}
.add-cart {
  opacity: 0;
}
.hover01:hover .add-cart {
  opacity: 1;
}
.hover01:hover {
  border: 1.2px solid #e35656;
}
</style>