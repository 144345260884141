import Cookies from "js-cookie";
export default {
    name: "Layout1",
    data: (vm) => ({
        top_header_background:
            "linear-gradient(to right, #F8AE26, #FBC156, #FBC156)",
        bottom_header_background: "#F6E5CC",
        button_color: "amber darken-1 white--text",
        nav_color: "deep-purple",
        lang: "1",
        items: [
            { id: "1", name: "Tiếng việt", image: require("../assets/vietnam.png") },
            { id: "2", name: "English", image: require("../assets/united.png") },
        ],
        shopId: "",
        userInfor: {
            phoneNumber: "",
            address: "",
            name: "",
        },
        timeout: 2000,
        text: "",
        snackbar: false,
        numberOrder: 0,
        zaloIcon: require("../assets/icon-zalo.png"),
        shopIcon: require("../assets/shop-icon-2.png"),
        productName: "",
        stateProduct: vm.$store.state.products,
    }),
    computed: {
        backgroundColor() {
            if (this.header_background.length > 1)
                return (
                    "linear-gradient(to right, " +
                    this.header_background[0] +
                    ", " +
                    this.header_background[1] +
                    " , " +
                    this.header_background[2] +
                    ")"
                );
            else {
                return this.header_background[0];
            }
        }
    },
    methods: {
        async getUserInfor() {
            let shopId = this.shopId;
            let res = await this.$store.dispatch("products/getUserInfor", {
                shopId,
            });
            if (typeof res.data == "undefined") this.$router.push("/");
            this.userInfor = res.data;
        },
        showOrderSuccess() {
            this.text = this.$t("message.orderSuccess");
            this.snackbar = true;
        },
        searchProduct: function () {
            this.stateProduct.textSearch = this.productName;
            let path = "/" + this.shopId + "/search";
            if (this.$route.path != path) this.$router.push(path);
        },
        changeLanguage: function (lang) {
            this.lang = lang || this.lang;
            let locale =
                this.lang == "1" ? "vi-VN" : this.lang == "2" ? "en-US" : "ja-JP";
            this.$i18n.locale = locale;
            Cookies.set("lang", this.lang);
        },
        isSearch() {
            let path = "/" + this.shopId;
            if (this.$route.path == path) return true;
            else return false;
        }

    },
    beforeMount() {
        this.lang = Cookies.get("lang") || this.lang;
        this.changeLanguage();
        this.shopId = this.$route.params.shopId;
        this.getUserInfor();
    },
}