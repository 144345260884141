<template>
  <div class="d-flex justify-center order-by" style="width: 100%">
    <li v-for="value in lstOrder" :key="value.active" class="item">
      <a
        :style="{color: active == value.active ? text_color: 'rgba(0,0,0,.6)'}"
        @click="
          active = value.active;
          OrderProuct(value.orderBy, value.sortBy, value.isFavorite);
        "
      >
        {{ $t(value.title) }}</a
      >
    </li>
  </div>
</template>
<script>
import mixin from "../mixins/OrderByMixin.js";
export default {
  name: "OrderBy",
  mixins: [mixin],
  props: {
    shopId: String,
    arrowHome: Boolean,
  },
  computed: {
    header_background() {
      return this.$store.state.products.backgroundColorLayout1;
    },
    text_color() {
      return this.$store.state.products.textColorLayout1;
    },
  },
  data: () => ({
    fab: false,
    active: 5,
    lstOrder: [
      {
        active: 1,
        orderBy: "name",
        sortBy: "asc",
        isFavorite: "",
        title: "message.nameDown",
      },
      {
        active: 2,
        orderBy: "name",
        sortBy: "desc",
        isFavorite: "",
        title: "message.nameUp",
      },
      {
        active: 3,
        orderBy: "salePrice",
        sortBy: "desc",
        isFavorite: "",
        title: "message.priceDown",
      },
      {
        active: 4,
        orderBy: "salePrice",
        sortBy: "asc",
        isFavorite: "",
        title: "message.priceUp",
      },
      {
        active: 5,
        orderBy: "updatedAt",
        sortBy: "desc",
        isFavorite: "",
        title: "message.newProduct",
      },
      {
        active: 6,
        orderBy: "",
        sortBy: "",
        isFavorite: "1",
        title: "message.like",
      },
    ],
  }),
};
</script>
<style >
.item {
  /* background: #f3f3f3; */
  padding: 5px 5px;
  margin-right: 9px;
  border-radius: 4px;
  list-style-type: none;
}
.order-by li {
  list-style: none;
  padding-left: 20px;
}
</style>