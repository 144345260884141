<template>
  <div>
    <ProductDetail v-if="layout == 0"> </ProductDetail>
    <ProductDetail1 v-if="layout == 1"> </ProductDetail1>
  </div>
</template>
<script>
import ProductDetail from "./ProductDetail.vue";
import ProductDetail1 from "./ProductDetail1.vue";
export default {
  name: "ProductDetailIndex",
  components: { ProductDetail, ProductDetail1 },
  data: () => ({}),
  computed: {
    layout() {
      return this.$store.state.products.layout;
    },
  },
};
</script>