<template>
  <div style="height: 100%">
    <div v-if="!$vuetify.breakpoint.xs" ref="cart">
      <v-btn text height="45" @click="dialog = true"
        ><v-icon :color="text_color">mdi-cart-variant</v-icon>
        <transition name="slide-fade" mode="out-in">
          <v-badge :value="sumOrderQty > 0" :key="sumOrderQty">
            <template v-slot:badge>
              <span :style="{ 'font-size': '13px' }">{{ sumOrderQty }}</span>
            </template>
          </v-badge>
        </transition></v-btn
      >
    </div>
    <div v-if="$vuetify.breakpoint.xs" ref="cart" style="height: 100%">
      <v-btn
        @click="dialog = true"
        class="btn-mobile-oder-font"
        style="height: 100%"
      >
        <span class="pl-2">{{ $t("message.purchaseOrder") }}</span>
        <v-icon>mdi-cart-variant</v-icon>
        <transition name="slide-fade" mode="out-in">
          <v-badge
            :value="sumOrderQty > 0"
            :key="sumOrderQty"
            :color="text_color"
            left
            :content="sumOrderQty"
            offset-y="12"
            offset-x="20"
            @click="dialog = true"
          ></v-badge>
        </transition>
      </v-btn>
    </div>
    <v-dialog
      v-model="dialog"
      :width="$vuetify.breakpoint.xs ? 900 : 800"
      :content-class="$vuetify.breakpoint.xs ? 'v-dialog-custom' : ''"
    >
      <v-card class="overflow-auto">
        <v-card-title
          class="text-h5 lighten-2 white--text pb-3"
          :style="{
            background:
              header_background.length >= 2
                ? header_background[2]
                : header_background[0],
          }"
        >
          {{ $t("message.shoppingCart") }}
        </v-card-title>
        <v-expansion-panels width="80%" v-model="panel">
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            top
            :color="text_color"
          ></v-progress-linear>
          <v-expansion-panel>
            <v-expansion-panel-header class="cart-title">
              {{ $t("message.orderInfo") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text
                v-if="!$vuetify.breakpoint.xs"
                class="font-size pt-0"
              >
                <v-row style="width: 100%" class="mt-0 pt-0 mb-1">
                  <v-col class="cart-title" cols="5">{{
                    $t("message.product")
                  }}</v-col>
                  <v-col class="cart-title" cols="2">{{
                    $t("message.unitPrice")
                  }}</v-col>
                  <v-col class="cart-title" cols="2">{{
                    $t("message.quantity")
                  }}</v-col>
                  <v-col class="cart-title" cols="3">{{
                    $t("message.totalPrice")
                  }}</v-col>
                </v-row>
                <div v-for="item in lstOrder" :key="item.productCode">
                  <v-divider></v-divider>
                  <v-row style="width: 100%" class="mt-0 pt-0 mb-2">
                    <v-col cols="2" class="mt-auto mb-auto cart-body">
                      <v-img
                        :src="item.pathImage || noImage"
                        class="rounded"
                        height="80"
                      ></v-img
                    ></v-col>
                    <v-col cols="3" class="mt-auto mb-auto pb-3">{{
                      item.productName
                    }}</v-col>
                    <v-col cols="2" class="mt-auto mb-auto pb-3 cart-body">{{
                      formatMoney(item.productPrice)
                    }}</v-col>
                    <v-col cols="2" class="mt-auto mb-auto cart-body pb-3">
                      <v-btn
                        fab
                        dark
                        x-small
                        color="grey"
                        class="ml-2 custom-x-small"
                        @click="downNumber(item.productCode)"
                      >
                        <v-icon> mdi-minus</v-icon>
                      </v-btn>
                      {{ item.productNumber }}
                      <v-btn
                        fab
                        dark
                        x-small
                        :color="
                          header_background.length >= 2
                            ? header_background[2]
                            : header_background[0]
                        "
                        class="custom-x-small"
                        @click="upNumber(item.productCode)"
                      >
                        <v-icon> mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="3" class="mt-auto mb-auto cart-body pb-2">
                      {{ formatMoney(item.productTotal) }}
                    </v-col>
                  </v-row>
                </div>
                <v-divider></v-divider>
                <v-row style="width: 100%" class="mt-3">
                  <v-col class="cart-title" cols="9">{{
                    $t("message.total")
                  }}</v-col>
                  <v-col class="cart-title" cols="3">{{
                    formatMoney(sumOrder)
                  }}</v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-if="$vuetify.breakpoint.xs" class="font-size pa-0">
                <v-row style="width: 100%" class="mt-0 pt-0 mb-0">
                  <v-col class="cart-title" cols="4">{{
                    $t("message.product")
                  }}</v-col>
                  <v-col class="cart-title" cols="4">{{
                    $t("message.unitPrice")
                  }}</v-col>
                  <v-col class="cart-title" cols="4">{{
                    $t("message.totalPrice")
                  }}</v-col>
                </v-row>
                <div
                  v-for="item in lstOrder"
                  :key="item.productCode"
                  class="mb-2"
                >
                  <v-divider></v-divider>
                  <v-row style="width: 100%" class="mt-0 pt-0 mb-0 pb-0">
                    <v-col cols="12" class="mt-auto mb-auto pb-0"
                      >{{
                        item.productName.length > 44
                          ? item.productName
                          : item.productName
                      }}
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="4" class="mt-auto mb-auto pb-3">
                      <span class="font-weight-black"
                        >{{ $t("message.qty") }}: </span
                      >{{ item.productNumber }}
                    </v-col>
                    <v-col cols="4" class="mt-auto mb-auto pb-3">
                      {{ formatMoney(item.productPrice) }}
                    </v-col>
                    <v-col cols="4">
                      {{ formatMoney(item.productTotal) }}
                    </v-col>
                  </v-row>
                </div>
                <v-divider></v-divider>
                <v-row style="width: 100%" class="mt-2">
                  <v-col class="cart-title" cols="8">{{
                    $t("message.total")
                  }}</v-col>
                  <v-col class="cart-title" cols="4">{{
                    formatMoney(sumOrder)
                  }}</v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="cart-title">
              {{ $t("message.customerInfo") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form ref="form" v-model="valid" lazy-validation>
                <div>
                  <v-row>
                    <v-col
                      ><v-text-field
                        :label="$t('message.name')"
                        dense
                        outlined
                        append-icon="mdi-account"
                        v-model="customerName"
                        required
                        :rules="nameRules"
                      ></v-text-field
                    ></v-col>
                    <v-col v-if="!$vuetify.breakpoint.xs"
                      ><v-text-field
                        :label="$t('message.phoneNumber')"
                        dense
                        outlined
                        append-icon="mdi-cellphone-basic"
                        v-model="customerPhone"
                        required
                        :rules="phoneRules"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-text-field
                    v-if="$vuetify.breakpoint.xs"
                    :label="$t('message.phoneNumber')"
                    nu
                    dense
                    outlined
                    append-icon="mdi-cellphone-basic"
                    v-model="customerPhone"
                    required
                    :rules="phoneRules"
                  ></v-text-field>
                  <v-text-field
                    :label="$t('message.address')"
                    dense
                    outlined
                    append-icon="mdi-map-marker"
                    class="pb-0"
                    v-model="customerAddress"
                    required
                    :rules="addressRules"
                  ></v-text-field>
                  <v-text-field
                    :label="$t('message.note')"
                    dense
                    outlined
                    append-icon="mdi-clipboard-text-outline"
                    class="pb-0"
                    v-model="customerNote"
                  ></v-text-field>
                  <v-row>
                    <v-col>
                      <v-radio-group v-model="paymentType" class="mt-0 pt-0">
                        <template v-slot:label>
                          <div>{{ $t("message.paymentType") }}</div>
                        </template>
                        <v-radio value="0" :color="header_background[0]">
                          <template v-slot:label>
                            <div class="title-over-size">
                              {{ $t("message.cash") }}
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="1" :color="header_background[0]">
                          <template v-slot:label>
                            <div class="title-over-size">
                              {{ $t("message.tranfer") }}
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="2" :color="header_background[0]">
                          <template v-slot:label>
                            <div class="title-over-size">
                              {{ $t("message.eWallet") }}
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col>
                      <v-radio-group
                        v-model="deliveryType"
                        class="mt-0 pt-0 title-over-size"
                      >
                        <template v-slot:label>
                          <div class="title-over-size">
                            {{ $t("message.receivingType") }}
                          </div>
                        </template>
                        <v-radio value="1" :color="header_background[0]">
                          <template v-slot:label>
                            <div class="title-over-size">
                              {{ $t("message.shopDelivery") }}
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="0" :color="header_background[0]">
                          <template v-slot:label>
                            <div class="title-over-size">
                              {{ $t("message.homeDelivery") }}
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="deep-purple darken-1 white--text"
            @click="dialog = false"
          >
            {{ $t("message.exit") }}
          </v-btn>
          <v-btn
            :color="
              header_background.length >= 2
                ? header_background[2]
                : header_background[0]
            "
            class="white--text"
            :loading="loading"
            :disabled="loading"
            @click="confirm"
          >
            {{ $t(strOrderAction) }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar
        v-model="snackbarFail"
        centered
        :timeout="timeout"
        color="red"
      >
        <v-icon color="white">mdi-alert</v-icon>
        <span class="ml-2">{{ text }}</span>
      </v-snackbar>
    </v-dialog>
    <v-dialog v-model="dialogConfirm" max-width="290" class="font-content">
      <v-card>
        <v-card-title class="text-h5">{{
          $t("message.confirmOrder")
        }}</v-card-title>
        <v-card-text>
          <v-icon size="18">mdi-account</v-icon> {{ customerName }}
          <v-spacer></v-spacer>
          <v-icon size="18">mdi-cellphone-basic</v-icon> {{ customerPhone }}
          <v-spacer></v-spacer>
          <v-icon size="18">mdi-map-marker</v-icon> {{ customerAddress }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="deep-purple darken-1 white--text"
            @click="dialogConfirm = false"
          >
            {{ $t("message.cancel") }}
          </v-btn>

          <v-btn
            :color="
              header_background.length >= 2
                ? header_background[2]
                : header_background[0]
            "
            class="white--text"
            @click="addOrder"
          >
            {{ $t("message.OK") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import mixin from "../mixins/ShoppingCartMixin.js";
export default {
  name: "ShoppingCart",
  mixins: [mixin],
  props: {
    currencyCode: String,
    buttonColor: String,
    shopId: String,
    header_background: Array,
    text_color: String,
  },
};
</script>
<style scoped>
.btn-mobile-oder-font {
  font-family: "Roboto", sans-serif !important;
  font-weight: bold;
  font-size: 0.87rem !important;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>