<template>
  <div
    class="mb-5 text-font body-bg"
    :style="
      $vuetify.breakpoint.xs
        ? lstProducts.length < 3
          ? 'height:105vh'
          : ''
        : lstProducts.length < 13
        ? 'height:131vh'
        : ''
    "
  >
    <div
      :class="
        $vuetify.breakpoint.xs
          ? 'main-bg pb-2 pl-2 pr-2 mt-2 mb-7'
          : 'main-bg pb-2 pl-2 pr-2 mt-2'
      "
    >
      <div class="header pb-0 pl-1 pt-3 right">
        <div class="d-flex">
          <div :style="localeLang == 'vi-VN' ? 'width: 80%' : 'width: 60%'">
            <h3
              class="title-over-size"
              :style="{ color: header_background[0] }"
            >
              {{ $t(titleProduct) }}
              <span v-if="isSearch">({{ productCount }})</span>
            </h3>
          </div>
          <div
            v-if="isFavorite == 1 || isSearch"
            style="width: 60%; text-align: right"
          >
            <h4 class="title-over-size">
              <a @click="BackToHome()" class="green--text ml-2"
                >{{ $t("message.home") }}
                <v-icon size="20">mdi-arrow-right</v-icon></a
              >
            </h4>
          </div>
        </div>
        <v-divider class="divider-style mt-1"></v-divider>
      </div>
      <div class="ml-1 mr-4 mt-4">
        <v-row>
          <v-col
            v-for="(value, index) in lstProducts"
            :key="value.barcode"
            :cols="$vuetify.breakpoint.xs ? 6 : $vuetify.breakpoint.xl ? 2 : 2"
            :ref="(index + 20)" :id="deliveryImage"
          >
            <v-card class="hover01" max-width="344" height="280.5">
              <div @click="showProductDetail(value.barcode)">
                <div>
                  <figure>
                    <v-img
                      :src="
                        !errorImage.includes(value.imagePath)
                          ? value.imagePath || noImage
                          : noImage
                      "
                      v-on:error="errImage(value.imagePath)"
                      height="150"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            :color="header_background[0]"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </figure>
                </div>
                <v-card-title class="product-name">
                  <div class="product-name-length">
                    {{
                      value.name.length > 38
                        ? value.name.substring(0, 38) + " ..."
                        : value.name
                    }}
                  </div>
                </v-card-title>
                <v-card-subtitle
                  class="pb-0 font-weight-bold product-price"
                  :style="{
                    color:
                      header_background.length >= 2
                        ? header_background[2]
                        : header_background[0],
                  }"
                >
                  {{ formatMoney(value.salePrice) }}
                </v-card-subtitle>
              </div>
              <v-card-actions align="center" justify="center">
                <v-row>
                  <v-col>
                    <v-btn
                      fab
                      dark
                      x-small
                      color="grey"
                      class="ml-2"
                      @click="
                        downNumber(
                          value.barcode,
                          value.name,
                          value.salePrice,
                          value.imagePath
                        )
                      "
                      v-show="existProducts(value.barcode)"
                    >
                      <v-icon> mdi-minus</v-icon>
                    </v-btn>
                    <span
                      v-show="existProducts(value.barcode)"
                      :id="value.barcode"
                      class="text-h6 mr-2 ml-2 number-order"
                      >{{ value.qty }}</span
                    >
                    <v-btn
                      fab
                      dark
                      x-small
                      :color="
                        header_background.length >= 2
                          ? header_background[2]
                          : header_background[0]
                      "
                      @click="
                        upNumber(
                          value.barcode,
                          value.name,
                          value.salePrice,
                          value.imagePath
                        );
                        doFly((index + 20), $vuetify.breakpoint.xs);
                      "
                    >
                      <v-icon> mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-progress-circular
            indeterminate
            color="primary"
            class="mt-5 ml-auto mr-auto mb-5"
            v-if="loading"
          ></v-progress-circular>
        </v-row>
        <div class="text-center pb-3 pt-7 ml-auto mr-auto">
          <v-pagination
            v-model="page"
            :length="productSize"
            :total-visible="$vuetify.breakpoint.xs ? 6 : 10"
            :color="
              header_background.length >= 2
                ? header_background[2]
                : header_background[0]
            "
            circle
          ></v-pagination>
        </div>
      </div>
    </div>
    <v-snackbar v-model="snackbar" top right :timeout="timeout" color="green" class="mt-16 pt-5">
      <v-icon color="white">mdi-check</v-icon> {{ text }}
    </v-snackbar>
    <v-snackbar v-model="snackbarFail" centered :timeout="timeout" color="red">
      <v-icon color="white">mdi-warning</v-icon> {{ text }}
    </v-snackbar>
    <MoveTop />
    <transition name="fly" @before-enter="beforeEnter" @enter="enter" appear enter-class="">
      <img :src="flyingImageSrc" class="fly" v-if="showFlyingImage" />
    </transition>
  </div>
</template>
<script>
import MoveTop from "../components/MoveTop.vue";
import mixin from "../mixins/ListProductMixin.js";
export default {
  name: "ListProduct",
  components: { MoveTop },
  mixins: [mixin],
  computed: {
    header_background() {
      return this.stateProduct.backgroundColorLayout;
    },
    text_color() {
      return this.stateProduct.textColorLayout;
    },
  },
};
</script>
<style>
@import "../styles/home.css";
@import "../styles/font.css";
.fly {
  position: fixed;
  transform: scale(0.5);
  opacity: 0.5;
  z-index: 9999;
}
</style>