import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const routes = [
  { path: '*', component: require('@/views/Error').default },
  {
    path: '/:shopId', component: require('@/layouts/index').default,
    children: [
      { path: '/:shopId', component: require('@/views/Home/HomeIndex').default },
      { path: '/:shopId/favorite', component: require('@/views/Favorite').default },
      { path: '/:shopId/search', component: require('@/views/Search/SearchIndex').default },
      { path: '/:shopId/product/:barcode', component: require('@/views/ProductDetail/ProductDetailIndex').default, meta: {title:'List'} },
    ]
  }
]
const router = new Router({
  mode: 'history',
  routes // short for `routes: routes`
});

export default router;