import axios from 'axios';

const baseUrl = "https://asia-northeast2-paper-pos-vn.cloudfunctions.net/apigw";

async function getAllProducts(shopId, limit, number, orderBy, sortBy, isFavorite) {
    const response = await axios.get(`${baseUrl}/getAllProducts`, {
        params: {
            shopId: shopId,
            limit: limit,
            page: number,
            orderBy: orderBy,
            sortBy: sortBy,
            isFavorite: isFavorite
        }
    });
    const products = response.data;
    return products;
}

async function getProductsFavorite(shopId, limit, number, orderBy, sortBy) {
    const response = await axios.get(`${baseUrl}/getProductsFavorite`, {
        params: {
            shopId: shopId,
            limit: limit,
            page: number,
            orderBy: orderBy,
            sortBy: sortBy
        }
    });
    const products = response.data;
    return products;
}

async function addOrders(data) {
    const response = await axios.post(`${baseUrl}/addOrders`, {
        shopId: data.shopId,
        customerName: data.customerName,
        customerAddress: data.customerAddress,
        customerPhone: data.customerPhone,
        sumOrder: data.sumOrder,
        tableId: data.tableId,
        tableName: data.tableName,
        products: data.products,
        paymentType: data.paymentType,
        deliveryType: data.deliveryType,
        customerNote: data.customerNote,
    }
    );
    const orderId = response.data;
    return orderId;
}

async function getProductsLength(shopId) {
    const response = await axios.get(`${baseUrl}/getProductsLength`, {
        params: {
            shopId: shopId
        }
    });
    const products = response.data;
    return products;
}

async function getProductFavoriteLength(shopId) {
    const response = await axios.get(`${baseUrl}/getProductFavoriteLength`, {
        params: {
            shopId: shopId
        }
    });
    const products = response.data;
    return products;
}


async function getUserInfor(shopId) {
    try {
        const response = await axios.get(`${baseUrl}/getUserInfor`, {
            params: {
                shopId: shopId
            }
        });
        const products = response.data;
        return products;
    } catch (e) {
        console.log();
    }
}

async function findProduct(shopId, limit, number, search) {
    const response = await axios.get(`${baseUrl}/findProduct`, {
        params: {
            shopId: shopId,
            limit: limit,
            page: number,
            search: search
        }
    });
    const products = response.data;
    return products;
}

async function getOneProduct(shopId, barcode) {
    const response = await axios.get(`${baseUrl}/getOneProduct`, {
        params: {
            shopId: shopId,
            barcode: barcode,
        }
    });
    const products = response.data;
    return products;
}

export const productService = {
    getAllProducts,
    getProductsFavorite,
    addOrders,
    getProductsLength,
    getProductFavoriteLength,
    getUserInfor,
    findProduct,
    getOneProduct
}