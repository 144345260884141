<template>
  <div>
    <Search v-if="layout == 0"> </Search>
    <Search1 v-if="layout == 1"> </Search1>
  </div>
</template>
<script>
import Search from "./Search.vue";
import Search1 from "./Search1.vue";
export default {
  name: "HomeIndex",
  components: { Search, Search1 },
  data: () => ({}),
  computed: {
    layout() {
      return this.$store.state.products.layout;
    },
  },
  methods: {},
  async beforeMount() {},
};
</script>