import Vuex from 'vuex';
import vue from 'vue';

// Modules
import * as modules from './module'

vue.use(Vuex);

export default new Vuex.Store({
    modules
})
