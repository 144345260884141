<template>
  <v-container>
    <ListProduct
      :titleProduct="titleProduct"
      :productType="productType"
      :productTypeLength="''"
      iconProduct="mdi-magnify"
      :search="search"
      :isSearch="true"
    ></ListProduct>
  </v-container>
</template>

<script>
import ListProduct from "../../components/ListProduct.vue";
export default {
  name: "home",
  components: { ListProduct },
  data: () => ({
    titleProduct: "message.searchResult",
    productType: "findProduct",
    productTypeLength: "findProductLength",
  }),
  computed: {
    search() {
      return this.$store.state.textSearch;
    },
  },
};
</script>