var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-bg pb-2 pr-1 mt-2"},[_c('div',{staticClass:"header pb-0 pl-3 pt-3 right"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticStyle:{"width":"80%"}},[_c('h3',{staticClass:"title-over-size",style:({ color: _vm.header_background[0] })},[_vm._v(" "+_vm._s(_vm.$t("message.favorite"))+" ")])]),_c('div',{staticStyle:{"width":"50%","text-align":"right"}},[_c('h4',{staticClass:"title-over-size"},[_c('a',{style:({ color: _vm.header_background[0] }),on:{"click":function($event){return _vm.SeeAll()}}},[_vm._v(_vm._s(_vm.$t("message.seeAll"))+" "),_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-arrow-right")])],1)])])]),_c('v-divider',{staticClass:"divider-style mt-1"})],1),(_vm.top20Products.length > 0)?_c('vue-horizontal',{staticClass:"horizontal mr-2"},_vm._l((_vm.top20Products),function(value,index){return _c('v-col',{key:value.barcode,ref:index,refInFor:true,attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 6 : _vm.$vuetify.breakpoint.xl ? 2 : 2,"id":_vm.deliveryImage}},[_c('v-card',{staticClass:"hover01",attrs:{"max-width":"344","height":"280.5"}},[_c('div',{on:{"click":function($event){return _vm.showProductDetail(value.barcode)}}},[_c('div',[_c('figure',[_c('v-img',{attrs:{"src":!_vm.errorImage.includes(value.imagePath)
                    ? value.imagePath || _vm.noImage
                    : _vm.noImage,"height":"150"},on:{"error":function($event){return _vm.errImage(value.imagePath)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm.header_background[0]}})],1)]},proxy:true}],null,true)})],1)]),_c('v-card-title',{staticClass:"product-name"},[_c('div',{staticClass:"product-name-length"},[_vm._v(" "+_vm._s(value.name.length > 38 ? value.name.substring(0, 38) + " ..." : value.name)+" ")])]),_c('v-card-subtitle',{staticClass:"pb-0 font-weight-bold product-price",style:({
              color:
                _vm.header_background.length >= 2
                  ? _vm.header_background[2]
                  : _vm.header_background[0],
            })},[_vm._v(" "+_vm._s(_vm.formatMoney(value.salePrice))+" ")])],1),_c('v-card-actions',{attrs:{"align":"center","justify":"center"}},[_c('v-row',[_c('v-col',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.existProducts(value.barcode)),expression:"existProducts(value.barcode)"}],staticClass:"ml-2",attrs:{"fab":"","dark":"","x-small":"","color":"grey"},on:{"click":function($event){return _vm.downNumber(
                    value.barcode,
                    value.name,
                    value.salePrice,
                    value.imagePath
                  )}}},[_c('v-icon',[_vm._v(" mdi-minus")])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.existProducts(value.barcode)),expression:"existProducts(value.barcode)"}],staticClass:"text-h6 mr-2 ml-2 number-order",attrs:{"id":value.barcode}},[_vm._v(_vm._s(value.qty))]),_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":_vm.header_background.length >= 2
                    ? _vm.header_background[2]
                    : _vm.header_background[0]},on:{"click":function($event){_vm.upNumber(
                    value.barcode,
                    value.name,
                    value.salePrice,
                    value.imagePath
                  );
                    _vm.doFly(index, _vm.$vuetify.breakpoint.xs);}}},[_c('v-icon',[_vm._v(" mdi-plus")])],1)],1)],1)],1)],1)],1)}),1):_vm._e(),_c('v-snackbar',{staticClass:"mt-16 pt-5",attrs:{"right":"","top":"","timeout":_vm.timeout,"color":"green"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(_vm.text)+" ")],1),_c('v-snackbar',{attrs:{"centered":"","timeout":_vm.timeout,"color":"red"},model:{value:(_vm.snackbarFail),callback:function ($$v) {_vm.snackbarFail=$$v},expression:"snackbarFail"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-warning")]),_vm._v(" "+_vm._s(_vm.text)+" ")],1),_c('transition',{attrs:{"name":"fly","appear":""},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter}},[(_vm.showFlyingImage)?_c('img',{staticClass:"fly",attrs:{"src":_vm.flyingImageSrc}}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }