<template>
  <v-container>
    <ListProduct :titleProduct="titleProduct" :isSearch="false"></ListProduct>
  </v-container>
</template>

<script>
import ListProduct from "../components/ListProduct.vue";
export default {
  name: "home",
  components: { ListProduct },
  data: () => ({
    titleProduct: "message.favorite",
  }),
  computed: {
    isReset() {
      return this.$store.state.isReset;
    },
    isCartUpDown() {
      return this.$store.state.isCartUpDown;
    },
  },
};
</script>
<style>
.body-bg {
  background-color: #f5f5f5;
}
</style>