import Velocity from "velocity-animate";
export default {
    data: (vm) => ({
        noImage: require("../assets/no-image.png"),
        deliveryImage: require("../assets/delivery4.png"),
        errorImage: [],
        loading: false,
        snackbar: false,
        snackbarFail: false,
        timeout: 1000,
        text: "",
        limit: 24,
        page: 1,
        lstProducts: [],
        top20Products: [],
        productSize: 0,
        productCount: 0,
        productSizeFavorite: 0,
        shopId: "",
        stateProduct: vm.$store.state.products,
        flyingImageSrc: "",
        showFlyingImage: false,
        itemImageOffsetLeft: 0,
        itemImageOffsetTop: 0,
        deltaX: 0,
        deltaY: 0,
        isMounted: false,
        isMobile: false,
    }),
    props: {
        bottom_background: String,
        titleProduct: String,
        isSearch: Boolean,
        iconProduct: String,
        isHome: Boolean
    },
    computed: {
        urlFavotire() {
            return "/" + this.shopId + "/favorite";
        },
        urlHome() {
            return "/" + this.shopId;
        },
        urlProductDetail() {
            return "/" + this.shopId + "/product/";
        },
        orderBy() {
            return this.stateProduct.orderBy;
        },
        sortBy() {
            return this.stateProduct.sortBy;
        },
        localeLang() {
            return this.$i18n.locale;
        },
        isReset() {
            return this.stateProduct.isReset;
        },
        search() {
            return this.stateProduct.textSearch;
        },
        isFavorite() {
            return this.stateProduct.isFavorite;
        },
        isCartUpDown() {
            return this.stateProduct.isCartUpDown;
        },
        cartOffsetLeft() {
            if (!this.isMounted) {
                return;
            }
            return this.stateProduct.cartOffsetLeft;
        },
        cartOffsetTop() {
            if (!this.isMounted) {
                return;
            }
            return this.stateProduct.cartOffsetTop;
        },
    },
    watch: {
        isReset: function () {
            if (this.isReset) {
                var tObj = document.getElementsByClassName("number-order");
                for (var i = 0; i < tObj.length; i++) {
                    tObj[i].innerHTML = "0";
                }
                this.stateProduct.isReset = false;
            }
        },
        isCartUpDown() {
            this.updateQtyCart();
        },
        page: function () {
            if (this.isSearch) this.findProduct(true);
            else this.getAllProduct();
        },
        orderBy: function () {
            this.getAllProduct();
        },
        sortBy: function () {
            this.getAllProduct();
        },
        search() {
            this.findProduct(false);
        },
    },
    methods: {
        async findProduct(pageSelected) {
            let shopId = this.shopId;
            let limit = this.limit;
            let number = this.page - 1;
            let search = this.search;
            let param = { shopId, limit, number, search };
            let res = await this.$store.dispatch("products/findProduct", param);
            this.lstProducts = res.data;
            if (res.pageSize >= 0 && !pageSelected) {
                this.productSize = Math.ceil(parseInt(res.pageSize) / this.limit);
                this.productCount = res.pageSize;
            }
            this.updateQty();
            this.$emit("searchResultCount", this.productCount);
        },
        async getAllProduct() {
            let shopId = this.shopId;
            let limit = this.limit;
            let number = this.page - 1;
            let orderBy = this.orderBy;
            let sortBy = this.sortBy;
            let isFavorite = this.isFavorite;
            let res = await this.$store.dispatch("products/getAllProducts", {
                shopId,
                limit,
                number,
                orderBy,
                sortBy,
                isFavorite,
            });
            this.lstProducts = res.data;
            console.log(this.lstProducts);
            this.updateQty();
        },
        async getAllProductLength() {
            let shopId = this.shopId;
            let res = await this.$store.dispatch("products/getProductsLength", {
                shopId,
            });
            this.productCount = res.data;
            console.log( this.productCount);
            this.productSize = Math.ceil(parseInt(res.data) / this.limit);
            console.log(this.productSize)
        },
        async getProductsFavorite() {
            let shopId = this.shopId;
            let limit = 10;
            let number = 0;
            let res = await this.$store.dispatch("products/getProductsFavorite", {
                shopId,
                limit,
                number,
            });
            this.top20Products = res.data;
            this.updateQty();
        },
        async getAllProductLengthFavorite() {
            let shopId = this.shopId;
            let res = await this.$store.dispatch("products/getProductFavoriteLength", {
                shopId,
            });
            this.productSizeFavorite = Math.round(parseInt(res.data) / this.limit);
            this.productCount = res.data;
        },
        formatMoney(value) {
            let currencyCode = this.stateProduct.currencyCode || "VND";
            let countryCode = this.stateProduct.countryCode || "vi-VN";
            var formatter = new Intl.NumberFormat(
                countryCode,
                {
                    style: "currency",
                    currency: currencyCode,
                    minimumFractionDigits: 0,
                }
            );
            return formatter.format(value);
        },
        upNumber(id, name, price, pathImage) {
            let numberOrder = document.getElementById(id).innerHTML;
            let up = parseInt(numberOrder) + 1;
            document.getElementById(id).innerHTML = up;
            this.selectProduct(id, name, price, pathImage, up);
            this.text = this.$t("message.addProduct", [up, name]);
            this.snackbar = true;
        },
        downNumber(id, name, price, pathImage) {
            let numberOrder = document.getElementById(id).innerHTML;
            let down = parseInt(numberOrder) - 1;
            if (down != 0) {
                document.getElementById(id).innerHTML = down;
                this.selectProduct(id, name, price, pathImage, down);
            } else this.removeProduct(id);
        },
        existProducts(productCode) {
            let found = this.stateProduct.lstProducts.some(
                (item) => item.productCode == productCode
            );
            return found ? true : false;
        },
        selectProduct(id, name, price, pathImage, numberOrder) {
            //this.snackbar = true;
            let total = parseInt(numberOrder) * parseInt(price);
            const index = this.stateProduct.lstProducts.findIndex(
                (key) => key.productCode == id
            );
            //Update product number
            if (index > -1) {
                let objProduct = {
                    productCode: id,
                    productNumber: numberOrder,
                    productTotal: total,
                };
                this.$store.dispatch("products/handleChangeLstProduct", objProduct);
            } else {
                //Add new product number to array
                let objProduct = {
                    productCode: id,
                    productName: name,
                    productPrice: price,
                    productNumber: numberOrder,
                    productTotal: total,
                    pathImage: pathImage,
                };
                this.$store.dispatch("products/handleChangeLstProduct", objProduct);
            }
        },
        removeProduct(id) {
            const index = this.stateProduct.lstProducts.findIndex(
                (key) => key.productCode == id
            );
            if (index > -1) {
                this.stateProduct.lstProducts.splice(index, 1);
                document.getElementById(id).innerHTML = 0;
            }
        },
        errImage(pathImage) {
            this.errorImage.push(pathImage);
        },
        updateQty() {
            this.stateProduct.lstProducts.forEach((item) => {
                let index = this.lstProducts.findIndex(
                    (el) => el.barcode == item.productCode
                );
                if (index > -1) {
                    this.lstProducts[index].qty = item.productNumber;
                } else {
                    index = this.top20Products.findIndex((el) => el.barcode == item.productCode);
                    if (index > -1) {
                        this.top20Products[index].qty = item.productNumber;
                    }
                }
            });
        },
        updateQtyCart() {
            this.stateProduct.lstProducts.forEach((item) => {
                document.getElementById(item.productCode).innerHTML =
                    item.productNumber;
            });
        },
        showProductDetail(barcode) {
            this.$router.push(this.urlProductDetail + barcode);
        },
        ShowOrder() {
            this.stateProduct.dialogOrder = true;
        },
        async BackToHome() {
            this.stateProduct.isFavorite = "";
            let path = this.urlHome;
            if (this.$route.path != path) this.$router.push(path);
        },
        doFly(ref, isMobile) {
            // get the image src (passed in)
            this.flyingImageSrc = this.$refs[ref][0].id;
            // turn on showing the image
            this.showFlyingImage = true;
            // turn off showing the image
            setTimeout(() => {
                this.showFlyingImage = false;
            }, 1000);
            // get image offset
            var elemRect = this.$refs[ref][0].getBoundingClientRect();
            const isFirefox = this.$browserDetect.isFirefox;
            this.itemImageOffsetLeft = elemRect.left - (isFirefox ? -7 : 70);
            this.itemImageOffsetTop = elemRect.top - (isFirefox ? -10 : 60);
            this.deltaY = this.cartOffsetTop - this.itemImageOffsetTop;
            this.deltaX = this.cartOffsetLeft - this.itemImageOffsetLeft;
            this.isMobile = isMobile;
        },
        beforeEnter(el) {
            el.style.top = this.itemImageOffsetTop + "px";
            el.style.left = this.itemImageOffsetLeft + "px";
            el.style.opacity = "0";
        },
        enter(el, done) {
            el.style.opacity = "1";
            el.style.transition = "all 1s";
            const isFirefox = this.$browserDetect.isFirefox;
            Velocity(el, {
                translateY: `${this.deltaY}`,
                translateX: `${this.deltaX}`,
                width: [isFirefox ? "30px" : "50px", isFirefox ? "180px" : "340px"],
                height: [isFirefox ? "30px" : "50px", isFirefox ? "155px" : "295px"],
            });
            done();
        },
    },
    async beforeMount() {
        this.shopId = this.$route.params.shopId;
        if (this.isSearch) {
            await this.findProduct(false);
        } else {
            await this.getAllProduct();
            await this.getAllProductLength();
            if (!this.isHome) await this.getAllProductLengthFavorite();
        }
    },
    mounted() {
        this.isMounted = true;
    },
}
