<template>
  <div
    class="ml-1 mr-4 mt-4"
    :style="
      $vuetify.breakpoint.xs
        ? lstProducts.length < 3
          ? 'height:105vh'
          : ''
        : lstProducts.length < 13
        ? 'height:131vh'
        : ''
    "
  >
    <v-row>
      <v-col
        v-for="(value, index) in lstProducts"
        :key="value.barcode"
        :cols="$vuetify.breakpoint.xs ? 6 : $vuetify.breakpoint.xl ? 2 : 2"
        :ref="index" :id="deliveryImage"
      >
        <v-card class="hover01" max-width="344" height="280.5">
          <div @click="showProductDetail(value.barcode)">
            <div>
              <figure>
                <v-img
                  :src="
                    !errorImage.includes(value.imagePath)
                      ? value.imagePath || noImage
                      : noImage
                  "
                  v-on:error="errImage(value.imagePath)"
                  height="150"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#F8AE26"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </figure>
              <div v-if="value.isFavorite == 1" class="like mt-4">
                <span class="ma-2">{{ $t("message.like") }}</span>
              </div>
            </div>
            <v-card-title class="product-name">
              <div class="product-name-length">
                {{
                  value.name.length > 38
                    ? value.name.substring(0, 38) + " ..."
                    : value.name
                }}
              </div>
            </v-card-title>
            <v-card-subtitle
              class="pb-0 font-weight-bold product-price"
              :style="{ color: text_color }"
            >
              {{ formatMoney(value.salePrice) }}
            </v-card-subtitle>
          </div>
          <div
            :class="
              existProducts(value.barcode) || $vuetify.breakpoint.xs
                ? ''
                : 'add-cart'
            "
          >
            <v-card-actions align="center" justify="center">
              <v-row>
                <v-col>
                  <v-btn
                    fab
                    dark
                    x-small
                    color="grey"
                    class="ml-2"
                    @click="
                      downNumber(
                        value.barcode,
                        value.name,
                        value.salePrice,
                        value.imagePath
                      )
                    "
                    v-show="existProducts(value.barcode)"
                  >
                    <v-icon> mdi-minus</v-icon>
                  </v-btn>
                  <span
                    v-show="existProducts(value.barcode)"
                    :id="value.barcode"
                    class="text-h6 mr-2 ml-2 number-order"
                    >{{ value.qty }}</span
                  >
                  <v-btn
                    fab
                    dark
                    x-small
                    :color="text_color"
                    :title="$t('message.addToCart')"
                    @click="
                      upNumber(
                        value.barcode,
                        value.name,
                        value.salePrice,
                        value.imagePath
                      );
                      doFly(index, $vuetify.breakpoint.xs);
                    "
                  >
                    <v-icon> mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
      <v-progress-circular
        indeterminate
        color="primary"
        class="mt-5 ml-auto mr-auto mb-5"
        v-if="loading"
      ></v-progress-circular>
    </v-row>
    <div class="text-center pb-3 pt-7 ml-auto mr-auto">
      <v-pagination
        v-if="isFavorite == 1"
        v-model="page"
        :length="productSizeFavorite"
        :total-visible="10"
        :color="text_color"
        circle
      ></v-pagination>
      <v-pagination
        v-else
        v-model="page"
        :length="productSize"
        :total-visible="10"
        :color="text_color"
        circle
      ></v-pagination>
    </div>
    <v-snackbar v-model="snackbar" centered :timeout="timeout" right top class="mt-16 pt-5" color="green">
      <v-icon color="white">mdi-check</v-icon> {{ text }}
    </v-snackbar>
    <v-snackbar v-model="snackbarFail" centered :timeout="timeout" color="red">
      <v-icon color="white">mdi-warning</v-icon> {{ text }}
    </v-snackbar>
    <transition
      name="fly"
      @before-enter="beforeEnter"
      @enter="enter"
      appear
      enter-class=""
    >
      <img :src="flyingImageSrc" class="fly" v-if="showFlyingImage" />
    </transition>
  </div>
</template>

<script>
import mixin from "../mixins/ListProductMixin.js";
export default {
  name: "ListProduct1",
  mixins: [mixin],
  computed: {
    header_background() {
      return this.stateProduct.backgroundColorLayout1;
    },
    text_color() {
      return this.stateProduct.textColorLayout1;
    },
  },
};
</script>