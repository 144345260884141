<template>
  <v-sheet class="mx-auto order-by" max-width="700">
    <v-slide-group show-arrows>
      <v-slide-item v-for="value in lstOrder" :key="value.active">
        <div class="item">
          <a
            :style="{
              color: active == value.active ? text_color : 'rgba(0,0,0,.6)',
            }"
            @click="
              active = value.active;
              OrderProuct(value.orderBy, value.sortBy, value.isFavorite);
            "
          >
            {{ $t(value.title) }}</a
          >
        </div>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>
<script>
import mixin from "../mixins/OrderByMixin.js";
export default {
  name: "OrderBy",
  mixins: [mixin],
  props: {
    shopId: String,
    arrowHome: Boolean,
  },
  computed: {
    header_background() {
      return this.$store.state.products.backgroundColorLayout1;
    },
    text_color() {
      return this.$store.state.products.textColorLayout1;
    },
  },
  data: () => ({
    fab: false,
    active: 3,
    lstOrder: [
      {
        active: 1,
        orderBy: "name",
        sortBy: "asc",
        isFavorite: "",
        title: "message.nameDown",
      },
      {
        active: 2,
        orderBy: "name",
        sortBy: "desc",
        isFavorite: "",
        title: "message.nameUp",
      },
      {
        active: 3,
        orderBy: "updatedAt",
        sortBy: "desc",
        isFavorite: "",
        title: "message.newProduct",
      },
      {
        active: 4,
        orderBy: "",
        sortBy: "",
        isFavorite: "1",
        title: "message.like",
      },
      {
        active: 5,
        orderBy: "salePrice",
        sortBy: "desc",
        isFavorite: "",
        title: "message.priceDown",
      },
      {
        active: 6,
        orderBy: "salePrice",
        sortBy: "asc",
        isFavorite: "",
        title: "message.priceUp",
      },
    ],
  }),
};
</script>
<style>
.item {
  /* background: #f3f3f3; */
  padding: 5px 5px;
  border-radius: 4px;
  list-style-type: none;
}
.v-hl-svg {
  width: 35px !important;
  height: 35px !important;
}
.v-slide-group__next,
.v-slide-group__prev {
  min-width: 37px !important;
}
</style>