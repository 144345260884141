<template>
  <div class="banner-layout">
    <div class="d-flex justify-center mb-5 mt-4" :style="{ color: text_color }">
      <h3 class="title-over-size">
        {{ $t(titleProduct) }}
        <span>{{
          productResultCount >= 0 ? "(" + productResultCount + ")" : ""
        }}</span>
      </h3>
      <v-divider vertical class="ml-2" :style="{ background: text_color, 'border-width': '1.5px'}"></v-divider>
      <div class="">
        <h3 class="title-over-size">
          <!-- <a @click="ShowOrder()" v-if="!isSearch"
                ><v-icon size="18">mdi-order-alphabetical-ascending</v-icon>
              </a> -->
          <router-link :to="urlHome" class="ml-2" :style="{ color: text_color }"
            >{{ $t("message.home") }}
            <v-icon size="20" :color="text_color"
              >mdi-arrow-right</v-icon
            ></router-link
          >
        </h3>
      </div>
    </div>
    <ListProduct1
      :bottom_background="bottom_background"
      :shopId="shopId"
      :text_color="text_color"
      :titleProduct="titleProduct"
      :isSearch="true"
      @searchResultCount="searchResultCount"
    ></ListProduct1>
  </div>
</template>

<script>
import ListProduct1 from "../../components/ListProduct1.vue";
export default {
  name: "Search1",
  components: { ListProduct1 },
  data: () => ({
    text_color: "#5736CB",
    bottom_background: "#F8AE26",
    titleProduct: "message.searchResult",
    shopId: "",
    productResultCount: -1,
  }),
  computed: {
    isReset() {
      return this.$store.state.isReset;
    },
    isCartUpDown() {
      return this.$store.state.isCartUpDown;
    },
    urlHome() {
      return "/" + this.shopId;
    },
  },
  methods: {
    searchResultCount(count) {
      this.productResultCount = count;
    },
  },
  async beforeMount() {
    this.shopId = this.$route.params.shopId;
  },
};
</script>