<template>
  <!-- List 10 product favorite  -->
  <div class="main-bg pb-2 pr-1 mt-2">
    <div class="header pb-0 pl-3 pt-3 right">
      <div class="d-flex">
        <div style="width: 80%">
          <h3 class="title-over-size" :style="{ color: header_background[0] }">
            {{ $t("message.favorite") }}
          </h3>
        </div>
        <div style="width: 50%; text-align: right">
          <h4 class="title-over-size">
            <a @click="SeeAll()" :style="{ color: header_background[0] }"
              >{{ $t("message.seeAll") }}
              <v-icon size="20">mdi-arrow-right</v-icon></a
            >
          </h4>
        </div>
      </div>
      <v-divider class="divider-style mt-1"></v-divider>
    </div>
    <vue-horizontal class="horizontal mr-2" v-if="top20Products.length > 0">
      <v-col
        v-for="(value, index) in top20Products"
        :key="value.barcode"
        :cols="$vuetify.breakpoint.xs ? 6 : $vuetify.breakpoint.xl ? 2 : 2"
        :ref="index" :id="deliveryImage"
      >
        <v-card class="hover01" max-width="344" height="280.5">
          <div @click="showProductDetail(value.barcode)">
            <div>
              <figure>
                <v-img
                  :src="
                    !errorImage.includes(value.imagePath)
                      ? value.imagePath || noImage
                      : noImage
                  "
                  v-on:error="errImage(value.imagePath)"
                  height="150"
                  ><template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        :color="header_background[0]"
                      ></v-progress-circular>
                    </v-row> </template
                ></v-img>
              </figure>
            </div>
            <v-card-title class="product-name">
              <div class="product-name-length">
                {{
                  value.name.length > 38
                    ? value.name.substring(0, 38) + " ..."
                    : value.name
                }}
              </div>
            </v-card-title>
            <v-card-subtitle
              class="pb-0 font-weight-bold product-price"
              :style="{
                color:
                  header_background.length >= 2
                    ? header_background[2]
                    : header_background[0],
              }"
            >
              {{ formatMoney(value.salePrice) }}
            </v-card-subtitle>
          </div>
          <v-card-actions align="center" justify="center">
            <v-row>
              <v-col>
                <v-btn
                  fab
                  dark
                  x-small
                  color="grey"
                  class="ml-2"
                  @click="
                    downNumber(
                      value.barcode,
                      value.name,
                      value.salePrice,
                      value.imagePath
                    )
                  "
                  v-show="existProducts(value.barcode)"
                >
                  <v-icon> mdi-minus</v-icon>
                </v-btn>
                <span
                  v-show="existProducts(value.barcode)"
                  :id="value.barcode"
                  class="text-h6 mr-2 ml-2 number-order"
                  >{{ value.qty }}</span
                >
                <v-btn
                  fab
                  dark
                  x-small
                  :color="
                    header_background.length >= 2
                      ? header_background[2]
                      : header_background[0]
                  "
                  @click="
                    upNumber(
                      value.barcode,
                      value.name,
                      value.salePrice,
                      value.imagePath
                    );
                      doFly(index, $vuetify.breakpoint.xs);
                  "
                >
                  <v-icon> mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </vue-horizontal>
    <v-snackbar v-model="snackbar" right top :timeout="timeout" color="green" class="mt-16 pt-5">
      <v-icon color="white">mdi-check</v-icon> {{ text }}
    </v-snackbar>
    <v-snackbar v-model="snackbarFail" centered :timeout="timeout" color="red">
      <v-icon color="white">mdi-warning</v-icon> {{ text }}
    </v-snackbar>
     <transition name="fly" @before-enter="beforeEnter" @enter="enter" appear>
      <img :src="flyingImageSrc" class="fly" v-if="showFlyingImage" />
    </transition>
  </div>
</template>
<script>
import VueHorizontal from "vue-horizontal";
import ListProductMixin from "../mixins/ListProductMixin.js";
import OrderByMixin from "../mixins/OrderByMixin.js";
export default {
  name: "ListProduct",
  components: { VueHorizontal },
  mixins: [ListProductMixin, OrderByMixin],
  methods: {
    SeeAll() {
      this.OrderProuct("", "", "1");
      let path = this.urlFavotire;
      if (this.$route.path != path) this.$router.push(path);
    },
  },
  computed: {
    header_background() {
      return this.stateProduct.backgroundColorLayout;
    },
    text_color() {
      return this.stateProduct.textColorLayout;
    },
  },
  async beforeMount() {
    await this.getProductsFavorite();
  },
};
</script>