import { getAnalytics, logEvent } from "firebase/analytics";
import Cookies from "js-cookie";
export default {
  data: (vm) => ({
    multiLine: true,
    dialog: false,
    dialogConfirm: false,
    panel: 0,
    loading: false,
    valid: true,
    nameRules: [(v) => !!v || vm.$t("message.nameRequired")],
    phoneRules: [
      (v) => !!v || vm.$t("message.phoneRequired"),
      (v) =>
        (v && v.length <= 15 && v.length >= 8 && !isNaN(v)) ||
        vm.$t("message.phoneNotValid"),
    ],
    addressRules: [(v) => !!v || vm.$t("message.addressRequired")],
    icons: ["mdi-facebook"],
    customerPhone: "",
    customerAddress: "",
    customerName: "",
    customerNote: "",
    paymentType: "0",
    deliveryType: "1",
    strOrderAction: "message.confirm",
    isConfirmed: false,
    titleColorDefault: "linear-gradient(to right, #128341, #4cae49, #4cae49)",
    snackbarFail: false,
    timeout: 2500,
    text: null,
    noImage: require("../assets/no-image.png"),
    stateProduct: vm.$store.state.products,
  }),
  watch: {
    dialog: function () {
      if (!this.dialog) {
        this.loading = false;
        this.isConfirmed = false;
        this.panel = 0;
      }
    },
    panel: function () {
      if (this.panel == 0) {
        this.strOrderAction = "message.confirm";
        this.isConfirmed = false;
      } else {
        this.strOrderAction = "message.order";
        this.isConfirmed = true;
      }
    },
  },
  computed: {
    numberOrder: function () {
      return this.stateProduct.lstProducts.length > 0
        ? this.stateProduct.lstProducts.length
        : 0;
    },
    lstOrder: function () {
      return this.stateProduct.lstProducts != null
        ? this.stateProduct.lstProducts
        : [];
    },
    sumOrder: function () {
      let lstOrder = this.stateProduct.lstProducts;
      if (lstOrder.length > 0)
        return this.stateProduct.lstProducts
          .map((item) => item.productTotal)
          .reduce((prev, next) => prev + next);
      else return 0;
    },
    sumOrderQty: function () {
      let lstOrder = this.stateProduct.lstProducts;
      if (lstOrder.length > 0)
        return this.stateProduct.lstProducts
          .map((item) => item.productNumber)
          .reduce((prev, next) => prev + next);
      else return 0;
    },
  },
  methods: {
    async addOrder() {
      try {
        let products = this.lstOrder;
        this.dialogConfirm = false;
        if (this.lstOrder.length > 0 && this.valid) {
          let shopId = this.shopId;
          let sumOrder = this.sumOrder;
          let customerPhone = this.customerPhone || "";
          let customerAddress = this.customerAddress || "";
          let customerName = this.customerName || "";
          let customerNote = this.customerNote || "";
          let paymentType = this.paymentType;
          let deliveryType = this.deliveryType;
          let data = {
            shopId: shopId,
            customerName: customerName,
            customerAddress: customerAddress,
            customerPhone: customerPhone,
            sumOrder: sumOrder,
            tableId: null,
            tableName: null,
            products: products,
            paymentType: paymentType,
            deliveryType: deliveryType,
            customerNote: customerNote,
          };
          this.loading = true;
          let res = await this.$store.dispatch("products/orderProducts", data);
          if (res.code == "00") {
            this.stateProduct.lstProducts = [];
            this.dialog = false;
            this.stateProduct.isReset = true;
            this.customerNote = "";
            this.$emit("showOrderSuccess");
            //Save customer info to cookie
            Cookies.set("customerName", this.customerName, { expires: 365 });
            Cookies.set("customerPhone", this.customerPhone, { expires: 365 });
            Cookies.set("customerAddress", this.customerAddress, {
              expires: 365,
            });
            //Log event to firebase
            const analytics = getAnalytics();
            let logData = {
              merchant_id: this.shopId,
              total_money: sumOrder,
              customerName: customerName,
              customerAddress: customerAddress,
              customerPhone: customerPhone,
              paymentType: paymentType,
              deliveryType: deliveryType,
              customerNote: customerNote,
            };
            logEvent(analytics, "ONLINE_ORDERED", logData);
          } else {
            this.text = this.$t("message.orderFailure");
            this.snackbarFail = true;
          }
          this.loading = false;
          this.panel = 0;
          this.isConfirmed = false;
        } else {
          this.text = this.$t("message.warningOrder");
          this.snackbarFail = true;
        }
      } catch (err) {
        console.log(err);
      }
    },
    upNumber: function (id) {
      let index = this.stateProduct.lstProducts.findIndex(
        (item) => item.productCode == id
      );
      if (index != -1) {
        this.stateProduct.lstProducts[index].productNumber += 1;
        let numberOrder = this.stateProduct.lstProducts[index].productNumber;
        let price = this.stateProduct.lstProducts[index].productPrice;
        let total = parseInt(numberOrder) * parseInt(price);
        this.stateProduct.lstProducts[index].productTotal = total;
        this.stateProduct.isCartUpDown = !this.stateProduct.isCartUpDown;
      }
    },
    downNumber: function (id) {
      let index = this.stateProduct.lstProducts.findIndex(
        (item) => item.productCode == id
      );
      if (index != -1) {
        this.stateProduct.lstProducts[index].productNumber -= 1;
        let numberOrder = this.stateProduct.lstProducts[index].productNumber;
        let price = this.stateProduct.lstProducts[index].productPrice;
        let total = parseInt(numberOrder) * parseInt(price);
        this.stateProduct.lstProducts[index].productTotal = total;
        if (this.stateProduct.lstProducts[index].productNumber == 0)
          this.removeProduct(index, id);
        this.stateProduct.isCartUpDown = !this.stateProduct.isCartUpDown;
      }
    },
    removeProduct(index, id) {
      this.stateProduct.lstProducts.splice(index, 1);
      document.getElementById(id).innerHTML = 0;
    },
    formatMoney(value) {
      let currencyCode = this.currencyCode || "VND";
      let countryCode =
        currencyCode == "USD"
          ? "en-US"
          : currencyCode == "JPY"
            ? "ja-JP"
            : "vi-VN";
      this.stateProduct.currencyCode = currencyCode;
      this.stateProduct.countryCode = countryCode;
      var formatter = new Intl.NumberFormat(countryCode, {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: 0,
      });
      return formatter.format(value);
    },
    confirm() {
      if (this.isConfirmed) {
        this.valid = this.$refs.form.validate();
        if (this.valid) this.dialogConfirm = true;
      } else {
        this.panel = 1;
        this.isConfirmed = true;
      }
    },
    getCartPosition() {
      var elemRect = this.$refs.cart.getBoundingClientRect();
      this.stateProduct.cartOffsetLeft = elemRect.left;
      this.stateProduct.cartOffsetTop = elemRect.top
    }
  },
  beforeMount() {
    this.customerName = this.customerName || Cookies.get("customerName");
    this.customerPhone = this.customerPhone || Cookies.get("customerPhone");
    this.customerAddress =
      this.customerAddress || Cookies.get("customerAddress");
  },
  mounted() {
    this.getCartPosition();
  },
  created() {
    window.addEventListener("resize", this.getCartPosition);
  },
  destroyed() {
    window.removeEventListener("resize", this.getCartPosition);
  },
}