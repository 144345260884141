import { productService } from '../../services/products.service'

const state = {
    lstProducts: [],
    lstProductStores: [],
    isReset: false,
    isCartUpDown: false,
    textSearch: "",
    dialogDetail: false,
    dialogOrder: false,
    orderBy: "updatedAt",
    sortBy: "desc",
    isFavorite: "",
    currencyCode: "VND",
    countryCode: "vi-VN",
    layout: "",
    backgroundColorLayout: ["#128341", "#4cae49", "#4cae49"],
    textColorLayout: "#FFFFFF",
    backgroundColorLayout1: ["#F8AE26", "#FBC156", "#FBC156"],
    textColorLayout1: "#5736CB",
    cartOffsetLeft: 0,
    cartOffsetTopL: 0,
}

const mutations = {
    changeLstProduct(state, objProduct) {
        const index = state.lstProducts.findIndex((key) => key.productCode == objProduct.productCode);
        if (index > -1) {
            state.lstProducts[index].productNumber = objProduct.productNumber;
            state.lstProducts[index].productTotal = objProduct.productTotal;
        } else {
            state.lstProducts.push(objProduct);
        }
    }
};

const actions = {
    handleChangeLstProduct(context, lstProduct) {
        context.commit('changeLstProduct', lstProduct)
    },
    getAllProducts(context, { shopId, limit, number, orderBy,
        sortBy, isFavorite }) {
        const res = productService.getAllProducts(shopId, limit, number, orderBy, sortBy, isFavorite);
        return res;
    },
    getProductsFavorite(context, { shopId, limit, number, orderBy, sortBy }) {
        const res = productService.getProductsFavorite(shopId, limit, number, orderBy, sortBy);
        return res;
    },
    orderProducts(context, data) {
        const res = productService.addOrders(data);
        return res;
    },
    getProductsLength(context, { shopId }) {
        const res = productService.getProductsLength(shopId);
        return res;
    },
    getProductFavoriteLength(context, { shopId }) {
        const res = productService.getProductFavoriteLength(shopId);
        return res;
    },
    getUserInfor(context, { shopId }) {
        const res = productService.getUserInfor(shopId);
        return res;
    },
    findProduct(context, { shopId, limit, number, search }) {
        const res = productService.findProduct(shopId, limit, number, search);
        return res;
    },
    getOneProduct(context, { shopId, barcode }) {
        const res = productService.getOneProduct(shopId, barcode);
        return res;
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations
};