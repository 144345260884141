import { getAnalytics, logEvent } from "firebase/analytics";
import Velocity from "velocity-animate";
export default {
    data: (vm) => ({
        product: {
            name: "",
            salePrice: 0,
        },
        description: '',
        imagePaths: [],
        errImage: false,
        noImage: require("../assets/no-image.png"),
        deliveryImage: require("../assets/delivery4.png"),
        snackbar: false,
        snackbarFail: false,
        timeout: 1000,
        text: "",
        baseUrl: "https://teteshop.net",
        networks: [
            {
                network: "facebook",
                name: "Facebook",
                icon: require("../assets/facebook.png"),
                color: "#1877f2",
            },
            {
                network: "skype",
                name: "skype",
                icon: require("../assets/skyper.png"),
                color: "#00aff0",
            },
            {
                network: "telegram",
                name: "Telegram",
                icon: require("../assets/telegram.png"),
                color: "#0088cc",
            },
            {
                network: "email",
                name: "Email",
                icon: require("../assets/email.png"),
                color: "#333333",
            },
            {
                network: "twitter",
                name: "Twitter",
                icon: require("../assets/twitter.png"),
                color: "#1da1f2",
            },

        ],
        stateProduct: vm.$store.state.products,
        imageSelected: "",
        userInfor: {
            phoneNumber: "",
            address: "",
            name: "",
        },
        flyingImageSrc: "",
        showFlyingImage: false,
        itemImageOffsetLeft: 0,
        itemImageOffsetTop: 0,
        deltaX: 0,
        deltaY: 0,
        isMounted: false,
        isMobile: false,
    }),
    props: {
        text_color: String,
        bottom_background: String,
        money_color: String
    },
    watch: {
        isReset: function () {
            if (this.isReset) {
                this.lstProducts = [];
                var tObj = document.getElementsByClassName("number-order");
                for (var i = 0; i < tObj.length; i++) {
                    tObj[i].innerHTML = "0";
                }
                this.stateProduct.isReset = false;
            }
        },
        isCartUpDown() {
            this.updateQtyCart();
        },
        imageSelected() {
            this.product.imagePath = this.imagePaths[this.imageSelected];
        }
    },
    computed: {
        urlHome() {
            return "/" + this.shopId;
        },
        barcode() {
            return this.$route.params.barcode;
        },
        fullPath() {
            return this.baseUrl + this.$route.fullPath;
        },
        shopId() {
            return this.$route.params.shopId;
        },
        isCartUpDown() {
            return this.stateProduct.isCartUpDown;
        },
        isReset() {
            return this.stateProduct.isReset;
        },
        cartOffsetLeft() {
            if (!this.isMounted) {
                return;
            }
            return this.stateProduct.cartOffsetLeft;
        },
        cartOffsetTop() {
            if (!this.isMounted) {
                return;
            }
            return this.stateProduct.cartOffsetTop;
        },
    },
    methods: {
        async getOneProduct() {
            let shopId = this.shopId;
            let barcode = this.barcode;
            let res = await this.$store.dispatch("products/getOneProduct", {
                shopId,
                barcode,
            });
            this.product = res.data;
            if (Object.prototype.hasOwnProperty.call(this.product, 'imagePaths'))
                this.imagePaths = res.data.imagePaths || [];
            if (Object.prototype.hasOwnProperty.call(this.product, 'description'))
                this.description = res.data.description || '. ';
            this.updateQty();
        },
        upNumber(id, name, price, pathImage) {
            let numberOrder = document.getElementById(id).innerHTML;
            let up = parseInt(numberOrder) + 1;
            document.getElementById(id).innerHTML = up;
            this.selectProduct(id, name, price, pathImage, up);
            this.text = this.$t("message.addProduct", [up, name]);
            this.snackbar = true;
        },
        downNumber(id, name, price, pathImage) {
            let numberOrder = document.getElementById(id).innerHTML;
            let down = parseInt(numberOrder) - 1;
            if (down != 0) {
                document.getElementById(id).innerHTML = down;
                this.selectProduct(id, name, price, pathImage, down);
            } else this.removeProduct(id);
        },
        existProducts(productCode) {
            let found = this.stateProduct.lstProducts.some(
                (item) => item.productCode == productCode
            );
            return found ? true : false;
        },
        selectProduct(id, name, price, pathImage, numberOrder) {
            //this.snackbar = true;
            let total = parseInt(numberOrder) * parseInt(price);
            const index = this.stateProduct.lstProducts.findIndex(
                (key) => key.productCode == id
            );
            //Update product number
            if (index > -1) {
                let objProduct = {
                    productCode: id,
                    productNumber: numberOrder,
                    productTotal: total,
                };
                this.$store.dispatch("products/handleChangeLstProduct", objProduct);
            } else {
                //Add new product number to array
                let objProduct = {
                    productCode: id,
                    productName: name,
                    productPrice: price,
                    productNumber: numberOrder,
                    productTotal: total,
                    pathImage: pathImage,
                };
                this.$store.dispatch("products/handleChangeLstProduct", objProduct);
            }
        },
        removeProduct(id) {
            const index = this.stateProduct.lstProducts.findIndex(
                (key) => key.productCode == id
            );
            if (index > -1) {
                this.stateProduct.lstProducts.splice(index, 1);
                document.getElementById(id).innerHTML = 0;
            }
        },
        formatMoney(value) {
            let currencyCode = this.stateProduct.currencyCode || "VND";
            let countryCode = this.stateProduct.countryCode || "vi-VN";
            var formatter = new Intl.NumberFormat(countryCode, {
                style: "currency",
                currency: currencyCode,
                minimumFractionDigits: 0,
            });
            return formatter.format(value);
        },
        updateQtyCart() {
            this.stateProduct.lstProducts.forEach((item) => {
                document.getElementById(item.productCode).innerHTML =
                    item.productNumber;
            });
        },
        updateQty() {
            this.stateProduct.lstProducts.forEach((item) => {
                if (this.product.barcode == item.productCode) {
                    this.product.qty = item.productNumber;
                }
            });
        },
        async getUserInfor() {
            let shopId = this.shopId;
            let res = await this.$store.dispatch("products/getUserInfor", {
                shopId,
            });
            this.userInfor = res.data;
        },
        async closeShare() {
            await this.getUserInfor();
            //Log event to firebase
            const analytics = getAnalytics();
            let logData = {
                merchant_id: this.userInfor.mchId,
                name: this.userInfor.name,
                phoneNumber: this.userInfor.phoneNumber,
                address: this.userInfor.address,
                urlShare: this.fullPath,
            };
            logEvent(analytics, "SHARE_LINK", logData);
        },
        doFly(ref, isMobile) {
            // get the image src (passed in)
            this.flyingImageSrc = this.$refs[ref].id;
            // turn on showing the image
            this.showFlyingImage = true;
            // turn off showing the image
            setTimeout(() => {
                this.showFlyingImage = false;
            }, 1000);
            // get image offset
            var elemRect = this.$refs[ref].getBoundingClientRect();
            const isFirefox = this.$browserDetect.isFirefox;
            this.itemImageOffsetLeft = elemRect.left - (isFirefox ? -7 : 70);
            this.itemImageOffsetTop = elemRect.top - (isFirefox ? -10 : 60);
            this.deltaY = this.cartOffsetTop - this.itemImageOffsetTop;
            this.deltaX = this.cartOffsetLeft - this.itemImageOffsetLeft;
            this.isMobile = isMobile;
        },
        beforeEnter(el) {
            el.style.top = this.itemImageOffsetTop + "px";
            el.style.left = this.itemImageOffsetLeft + "px";
            el.style.opacity = "0";
        },
        enter(el, done) {
            el.style.opacity = "1";
            el.style.transition = "all 1s";
            const isFirefox = this.$browserDetect.isFirefox;
            Velocity(el, {
                translateY: `${this.deltaY}`,
                translateX: `${this.deltaX}`,
                width: [isFirefox ? "30px" : "50px", isFirefox ? "180px" : "340px"],
                height: [isFirefox ? "30px" : "50px", isFirefox ? "155px" : "295px"],
            });
            done();
        },
    },
    beforeMount() {
        window.scrollTo(0, 0);
        this.getOneProduct();
    },
    mounted() {
        this.isMounted = true;
    },
};