var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-5 text-font body-bg",style:(_vm.$vuetify.breakpoint.xs
      ? _vm.lstProducts.length < 3
        ? 'height:105vh'
        : ''
      : _vm.lstProducts.length < 13
      ? 'height:131vh'
      : '')},[_c('div',{class:_vm.$vuetify.breakpoint.xs
        ? 'main-bg pb-2 pl-2 pr-2 mt-2 mb-7'
        : 'main-bg pb-2 pl-2 pr-2 mt-2'},[_c('div',{staticClass:"header pb-0 pl-1 pt-3 right"},[_c('div',{staticClass:"d-flex"},[_c('div',{style:(_vm.localeLang == 'vi-VN' ? 'width: 80%' : 'width: 60%')},[_c('h3',{staticClass:"title-over-size",style:({ color: _vm.header_background[0] })},[_vm._v(" "+_vm._s(_vm.$t(_vm.titleProduct))+" "),(_vm.isSearch)?_c('span',[_vm._v("("+_vm._s(_vm.productCount)+")")]):_vm._e()])]),(_vm.isFavorite == 1 || _vm.isSearch)?_c('div',{staticStyle:{"width":"60%","text-align":"right"}},[_c('h4',{staticClass:"title-over-size"},[_c('a',{staticClass:"green--text ml-2",on:{"click":function($event){return _vm.BackToHome()}}},[_vm._v(_vm._s(_vm.$t("message.home"))+" "),_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-arrow-right")])],1)])]):_vm._e()]),_c('v-divider',{staticClass:"divider-style mt-1"})],1),_c('div',{staticClass:"ml-1 mr-4 mt-4"},[_c('v-row',[_vm._l((_vm.lstProducts),function(value,index){return _c('v-col',{key:value.barcode,ref:(index + 20),refInFor:true,attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 6 : _vm.$vuetify.breakpoint.xl ? 2 : 2,"id":_vm.deliveryImage}},[_c('v-card',{staticClass:"hover01",attrs:{"max-width":"344","height":"280.5"}},[_c('div',{on:{"click":function($event){return _vm.showProductDetail(value.barcode)}}},[_c('div',[_c('figure',[_c('v-img',{attrs:{"src":!_vm.errorImage.includes(value.imagePath)
                        ? value.imagePath || _vm.noImage
                        : _vm.noImage,"height":"150"},on:{"error":function($event){return _vm.errImage(value.imagePath)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm.header_background[0]}})],1)]},proxy:true}],null,true)})],1)]),_c('v-card-title',{staticClass:"product-name"},[_c('div',{staticClass:"product-name-length"},[_vm._v(" "+_vm._s(value.name.length > 38 ? value.name.substring(0, 38) + " ..." : value.name)+" ")])]),_c('v-card-subtitle',{staticClass:"pb-0 font-weight-bold product-price",style:({
                  color:
                    _vm.header_background.length >= 2
                      ? _vm.header_background[2]
                      : _vm.header_background[0],
                })},[_vm._v(" "+_vm._s(_vm.formatMoney(value.salePrice))+" ")])],1),_c('v-card-actions',{attrs:{"align":"center","justify":"center"}},[_c('v-row',[_c('v-col',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.existProducts(value.barcode)),expression:"existProducts(value.barcode)"}],staticClass:"ml-2",attrs:{"fab":"","dark":"","x-small":"","color":"grey"},on:{"click":function($event){return _vm.downNumber(
                        value.barcode,
                        value.name,
                        value.salePrice,
                        value.imagePath
                      )}}},[_c('v-icon',[_vm._v(" mdi-minus")])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.existProducts(value.barcode)),expression:"existProducts(value.barcode)"}],staticClass:"text-h6 mr-2 ml-2 number-order",attrs:{"id":value.barcode}},[_vm._v(_vm._s(value.qty))]),_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":_vm.header_background.length >= 2
                        ? _vm.header_background[2]
                        : _vm.header_background[0]},on:{"click":function($event){_vm.upNumber(
                        value.barcode,
                        value.name,
                        value.salePrice,
                        value.imagePath
                      );
                      _vm.doFly((index + 20), _vm.$vuetify.breakpoint.xs);}}},[_c('v-icon',[_vm._v(" mdi-plus")])],1)],1)],1)],1)],1)],1)}),(_vm.loading)?_c('v-progress-circular',{staticClass:"mt-5 ml-auto mr-auto mb-5",attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],2),_c('div',{staticClass:"text-center pb-3 pt-7 ml-auto mr-auto"},[_c('v-pagination',{attrs:{"length":_vm.productSize,"total-visible":_vm.$vuetify.breakpoint.xs ? 6 : 10,"color":_vm.header_background.length >= 2
              ? _vm.header_background[2]
              : _vm.header_background[0],"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)]),_c('v-snackbar',{staticClass:"mt-16 pt-5",attrs:{"top":"","right":"","timeout":_vm.timeout,"color":"green"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(_vm.text)+" ")],1),_c('v-snackbar',{attrs:{"centered":"","timeout":_vm.timeout,"color":"red"},model:{value:(_vm.snackbarFail),callback:function ($$v) {_vm.snackbarFail=$$v},expression:"snackbarFail"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-warning")]),_vm._v(" "+_vm._s(_vm.text)+" ")],1),_c('MoveTop'),_c('transition',{attrs:{"name":"fly","appear":"","enter-class":""},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter}},[(_vm.showFlyingImage)?_c('img',{staticClass:"fly",attrs:{"src":_vm.flyingImageSrc}}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }