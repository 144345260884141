import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import router from './router'
import VueAnalytics from 'vue-analytics';
import i18n from './lang/i18n'
import VueSocialSharing from 'vue-social-sharing'
import Meta from "vue-meta";
import * as firebase from "firebase/app";
import browserDetect from "vue-browser-detect-plugin";

const firebaseConfig = {
  apiKey: "AIzaSyDCztSN56y9NGs3VLU0uq6WyWH5tRl9z2M",
  authDomain: "paper-pos-vn.firebaseapp.com",
  databaseURL: "https://paper-pos-vn.firebaseio.com",
  projectId: "paper-pos-vn",
  storageBucket: "paper-pos-vn.appspot.com",
  messagingSenderId: "602445894149",
  appId: "1:602445894149:web:e5877945457d18dd6cc26c",
  measurementId: "G-6J0GR7FWXR"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false

Vue.use(VueSocialSharing);
Vue.use(browserDetect);
Vue.use(VueAnalytics, {
  id: 'UA-159111800-3',
  router
});

Vue.use(Meta);
new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
