<template>
  <div>
    <LayoutDefault v-if="layout == 0"> </LayoutDefault>
    <Layout1 v-if="layout == 1"> </Layout1>
  </div>
</template>
<script>
import LayoutDefault from "./LayoutDefault.vue";
import Layout1 from "./Layout1.vue";
export default {
  name: "LayoutIndex",
  components: { LayoutDefault, Layout1 },
  data: (vm) => ({
    shopId: "",
    userInfor: {
      phoneNumber: "",
      address: "",
      name: "",
    },
    layout: null,
    blocked: null,
    stateProduct: vm.$store.state.products,
  }),
  methods: {
    async getUserInfor() {
      let res;
        let shopId = this.shopId;
        res = await this.$store.dispatch("products/getUserInfor", {
          shopId,
        });
      //console.log(res.data);
      if (typeof res.data == "undefined") this.$router.push("/");
      this.userInfor = res.data;
      this.layout = res.data.layout || 0;
      this.blocked = res.data.blocked || false;
      this.$store.state.products.layout = this.layout;
      if (this.layout == 0) {
        this.stateProduct.backgroundColorLayout = res.data.wsBackgroundColor
          ? ["#" + res.data.wsBackgroundColor]
          : this.stateProduct.backgroundColorLayout;
        this.stateProduct.textColorLayout = res.data.wsTextColor
          ? "#" + res.data.wsTextColor
          : this.stateProduct.textColorLayout;
      } else {
        if (this.layout == 1) {
          this.stateProduct.backgroundColorLayout1 = res.data.wsBackgroundColor
            ? ["#" + res.data.wsBackgroundColor]
            : this.stateProduct.backgroundColorLayout1;
          this.stateProduct.textColorLayout1 = res.data.wsTextColor
            ? "#" + res.data.wsTextColor
            : this.stateProduct.textColorLayout1;
        }
      }
    },
  },
  async beforeMount() {
    try {
      this.shopId = this.$route.params.shopId;
      await this.getUserInfor();
      if (this.blocked) this.$router.push("/");
    } catch (e) {
      console.log();
    }
  },
};
</script>