export default {
    computed: {
        urlHome() {
            return "/" + this.shopId;
        },
    },
    methods: {
        OrderProuct(orderBy, sortBy, isFavorite) {
            this.$store.state.products.orderBy = orderBy;
            this.$store.state.products.sortBy = sortBy;
            this.$store.state.products.isFavorite = isFavorite;
        },
    },
}