<template>
  <v-container
    :class="
      $vuetify.breakpoint.xs ? 'mb-5 text-font' : 'mb-5 text-font body-bg'
    "
  >
    <!-- Favorite Products -->
    <FavoriteProduct></FavoriteProduct>
    <!-- List other products -->
    <ListProduct
      :titleProduct="titleProduct"
      :isSearch="false"
      :isHome="true"
    ></ListProduct>
    <v-snackbar v-model="snackbar" centered :timeout="timeout" color="green">
      <v-icon color="white">mdi-check</v-icon> {{ text }}
    </v-snackbar>
    <v-snackbar v-model="snackbarFail" centered :timeout="timeout" color="red">
      <v-icon color="white">mdi-warning</v-icon> {{ text }}
    </v-snackbar>
    <MoveTop :backgroundColor="header_background[0]" />
  </v-container>
</template>

<script>
import MoveTop from "../../components/MoveTop.vue";
import FavoriteProduct from "../../components/FavoriteProduct.vue";
import Mixin from "../../mixins/OrderByMixin.js";
import ListProduct from "../../components/ListProduct.vue";
export default {
  name: "home",
  components: { MoveTop, ListProduct, FavoriteProduct },
  mixins: [Mixin],
  data: () => ({
    titleProduct: "message.otherProduct",
    snackbar: false,
    snackbarFail: false,
    timeout: 1000,
    text: "",
  }),
  computed: {
    urlFavotire() {
      return "/" + this.shopId + "/favorite";
    },
    header_background() {
      return this.$store.state.products.backgroundColorLayout;
    },
    text_color() {
      return this.$store.state.products.textColorLayout;
    },
  },
  async beforeMount() {
    this.OrderProuct("", "", "");
  },
};
</script>

<style>
@import "../../styles/home.css";
@import "../../styles/font.css";
</style>
