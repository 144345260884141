<template>
  <v-container id="error-view" class="fill-height text-center" tag="section">
    <v-row justify="center">
      <v-img :src='error' width="200%" height="100%"></v-img>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ErrorView",
  data: () => ({
    error: require("../assets/404-2.gif"),
  }),
};
</script>

<style lang="sass">
#error-view h1.text-h1
  font-size: 14rem !important
</style>
