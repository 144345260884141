<template>
  <v-container
    class="mb-5 text-font banner-layout"
    :style="$vuetify.breakpoint.xs ? 'height:auto' : 'height:131vh'"
  >
    <!-- Title product detail -->
    <div class="d-flex justify-center mb-5 mt-4" :style="{ color: text_color }">
      <h3 class="title-over-size">
        {{ $t("message.productInfo") }}
      </h3>
      <v-divider
        vertical
        class="ml-2"
        :style="{ background: text_color, 'border-width': '1.5px' }"
      ></v-divider>
      <div class="">
        <h3 class="title-over-size">
          <!-- <a @click="ShowOrder()" v-if="!isSearch"
                ><v-icon size="18">mdi-order-alphabetical-ascending</v-icon>
              </a> -->
          <router-link :to="urlHome" class="ml-2" :style="{ color: text_color }"
            >{{ $t("message.home") }}
            <v-icon size="20" :color="text_color"
              >mdi-arrow-right</v-icon
            ></router-link
          >
        </h3>
      </div>
    </div>
    <!-- Body product detail -->
    <div
      :class="
        $vuetify.breakpoint.xs
          ? 'pb-2 pl-2 pr-2 mt-2 mb-7'
          : 'main-bg pb-2 pl-2 pr-2 mt-2'
      "
    >
      <ProductDetail
        :text_color="bottom_background"
        :bottom_background="text_color"
        :money_color="text_color"
      ></ProductDetail>
    </div>
  </v-container>
</template>
<script>
import ProductDetail from "../../components/ProductDetail.vue";
export default {
  components: { ProductDetail },
  computed: {
    urlHome() {
      return "/" + this.shopId;
    },
    shopId() {
      return this.$route.params.shopId;
    },
    bottom_background() {
      return this.$store.state.products.backgroundColorLayout1[0];
    },
    text_color() {
      return this.$store.state.products.textColorLayout1;
    },
  },
};
</script>
<style>
@import "../../styles/detail.css";
</style>
