<template>
  <v-container
    class="mb-5 text-font body-bg"
    :style="$vuetify.breakpoint.xs ? 'height:auto' : 'height:131vh'"
  >
    <div
      :class="
        $vuetify.breakpoint.xs
          ? 'main-bg pb-2 pl-2 pr-2 mt-2 mb-7'
          : 'main-bg pb-2 pl-2 pr-2 mt-2'
      "
    >
      <div class="header pb-3 pl-1 pt-2">
        <div class="d-flex">
          <div style="width: 80%">
            <h3 class="title-over-size" :style="{color: bottom_background}">
              {{ $t("message.productInfo") }}
            </h3>
          </div>
          <div style="width: 50%; text-align: right" class="">
            <h4 class="title-over-size" >
              <router-link :to="urlHome" :style="{color: bottom_background}"
                >{{ $t("message.home") }}
                <v-icon size="20">mdi-arrow-right</v-icon></router-link
              >
            </h4>
          </div>
        </div>
        <v-divider class="divider-style"></v-divider>
      </div>
      <ProductDetail
        :text_color="text_color"
        :bottom_background="bottom_background"
        :money_color="money_color"
      ></ProductDetail>
    </div>
  </v-container>
</template>
<script>
import ProductDetail from "../../components/ProductDetail.vue";

export default {
  components: { ProductDetail },
  data: () => ({
    money_color: "green",
  }),
  computed: {
    urlHome() {
      return "/" + this.shopId;
    },
    shopId() {
      return this.$route.params.shopId;
    },
    bottom_background() {
      return this.$store.state.products.backgroundColorLayout[0];
    },
    text_color() {
      return this.$store.state.products.textColorLayout;
    },
  },
};
</script>
<style>
@import "../../styles/detail.css";
</style>
