<template>
  <div>
    <Home v-if="layout == 0"> </Home>
    <Home1 v-if="layout == 1"> </Home1>
  </div>
</template>
<script>
import Home from "./Home.vue";
import Home1 from "./Home1.vue";
export default {
  name: "HomeIndex",
  components: { Home, Home1 },
  data: () => ({}),
  computed: {
    layout() {
      return this.$store.state.products.layout;
    },
  },
  methods: {},
  async beforeMount() {},
};
</script>