<template>
  <div class="LayoutDefault text-font">
    <!--Website layout -->
    <v-app-bar
      app
      dark
      height="80"
      class="app-bar"
      :style="{ background: backgroundColor, color: text_color }"
      v-if="!$vuetify.breakpoint.xs"
    >
      <div class="d-flex flex-row banner-layout" style="width: 100%">
        <div class="d-flex flex-row" style="width: 27.5%">
          <v-img
            alt="Shop Icon"
            class="shrink mr-2"
            contain
            src="../assets/shopicon.png"
            transition="scale-transition"
            width="55"
          />
          <!-- <v-icon v-else class="ml-2" size="50" :color="text_color"
            >mdi-storefront-outline</v-icon
          > -->
          <div class="mt-auto mb-auto">
            <h4 class="ml-1">
              {{
                userInfor.name.toUpperCase().length > 56
                  ? userInfor.name.toUpperCase().substring(0, 56) + "..."
                  : userInfor.name.toUpperCase()
              }}
            </h4>
          </div>
        </div>
        <div class="d-flex flex-row mt-auto mb-auto pt-2" style="width: 55%">
          <v-text-field
            flat
            solo-inverted
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
            :class="$vuetify.breakpoint.xs ? 'pl-0 pr-0' : 'pl-3 pb-2 pr-3'"
            v-model="productName"
          >
            <template v-slot:label>
              <div :style="{ color: text_color }">
                {{ $t("message.searchInfo") }}
              </div>
            </template>
            <template v-slot:append>
              <v-btn
                tile
                depressed
                :color="header_background[0]"
                class="ma-0"
                @click="searchProduct"
              >
                <v-icon :color="text_color">mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </div>
        <div class="mt-auto mb-auto">
          <div>
            <ShoppingCart
              :header_background="header_background"
              :text_color="text_color"
              :currencyCode="userInfor.currencyCode"
              :shopId="shopId"
              @showOrderSuccess="showOrderSuccess"
            ></ShoppingCart>
          </div>
        </div>
        <div class="ml-2 mt-auto mb-auto pt-3" style="width: 11%">
          <v-select
            :items="items"
            v-model="lang"
            item-value="id"
            item-text="name"
            class="custom mt-2"
            :color="text_color"
            @change="changeLanguage()"
          >
            <template v-slot:selection="{ item }">
              <img :src="item.image" width="20" class="mr-1" />
              <span :style="{ color: text_color }">{{ item.name }}</span>
            </template>
            <template v-slot:item="{ item }">
              <img :src="item.image" width="20" class="mr-3" />{{ item.name }}
            </template>
          </v-select>
        </div>
      </div>
    </v-app-bar>
    <!-- End website header -->

    <!-- Mobile layout -->
    <v-app-bar
      app
      dark
      height="80"
      class="app-bar"
      v-if="$vuetify.breakpoint.xs"
      :style="{ background: backgroundColor, color: text_color }"
    >
      <v-text-field
        flat
        solo-inverted
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
        v-model="productName"
        class="fix-header"
      >
        <template v-slot:label>
          <div :style="{ color: text_color }">
            {{ $t("message.searchInfo") }}
          </div>
        </template>
        <template v-slot:append>
          <v-btn
            tile
            depressed
            :style="{ background: header_background[0] }"
            class="ma-0"
            @click="searchProduct"
          >
            <v-icon :color="text_color">mdi-magnify</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-app-bar>
    <v-card
      v-if="$vuetify.breakpoint.xs && isSearch()"
      flat
      class="v-card-custom"
      color="#f5f5f5"
    >
      <v-toolbar
        flat
        height="90"
        :style="{ background: backgroundColor, color: text_color }"
      >
      </v-toolbar>
      <v-card class="mx-auto" max-width="94%" style="margin-top: -90px">
        <v-card-text>
          <v-row>
            <v-col
              ><v-img
                alt="Shop Icon"
                class="shrink mr-2"
                contain
                :src="shopIcon"
                transition="scale-transition"
                width="55"
            /></v-col>
            <v-col cols="6"
              ><v-select
                :items="items"
                v-model="lang"
                item-value="id"
                item-text="name"
                class="custom mt-2"
                @change="changeLanguage()"
              >
                <template v-slot:selection="{ item }">
                  <img :src="item.image" width="20" class="mr-2" />{{
                    item.name
                  }}
                </template>
                <template v-slot:item="{ item }">
                  <img :src="item.image" width="20" class="mr-3" />{{
                    item.name
                  }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <p class="text-h6 text--primary mb-0">
            {{ userInfor.name.toUpperCase() }}
          </p>
          <div class="text-color">
            <v-icon size="20" class="mr-1 pb-1 text-color"
              >mdi-cellphone-basic</v-icon
            >{{ userInfor.phoneNumber }}
          </div>
          <div class="text-color">
            <v-icon size="20" class="mr-1 pt-0 pb-1 text-color"
              >mdi-map-marker-radius</v-icon
            >{{ userInfor.address }}
          </div>
        </v-card-text>
      </v-card>
    </v-card>
    <!-- End mobile layout-->

    <!-- Body -->
    <v-main class="body-bg pt-0">
      <router-view></router-view>
    </v-main>

    <!-- Footer -->
    <v-footer dark padless>
      <v-card class="flex" flat tile>
        <div
          :class="$vuetify.breakpoint.xs ? 'footer-bg mb-14' : 'footer-bg'"
          :style="{ background: backgroundColor, color: text_color }"
        >
          <v-card-title class="ml-auto mr-auto footer-content">
            <div class="mt-auto mb-auto footer-content">
              <h3 class="ml-1">
                {{ userInfor.name.toUpperCase() }}
              </h3>
              <h4>
                <v-icon size="15" class="mr-1" :color="text_color">mdi-map-marker</v-icon
                >{{ userInfor.address }}
              </h4>
              <h4>
                <v-icon size="15" class="mr-1" :color="text_color">mdi-cellphone-basic</v-icon
                >{{ userInfor.phoneNumber }}
              </h4>
            </div>
            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-text class="py-2 black--text text-center footer-end">
            <a
              href="https://www.netstars.vn/"
              target="_blank"
              class="black--text"
            >
              <strong
                >Copyright {{ new Date().getFullYear() }} | by Netstars Việt Nam
                <v-icon :color="header_background[0]"
                  >mdi-arrow-right-bold-circle</v-icon
                ></strong
              ></a
            >
          </v-card-text>
        </div>
      </v-card>
    </v-footer>
    <!-- Show notification -->
    <v-snackbar v-model="snackbar" centered :timeout="timeout" color="green">
      <v-icon color="white">mdi-check</v-icon> {{ text }}
    </v-snackbar>
    <!-- Navigation for mobile -->
    <v-bottom-navigation
      :color="header_background[0]"
      class="footer nav-font"
      v-if="$vuetify.breakpoint.xs"
      grow
    >
      <div>
        <ShoppingCart
          :header_background="header_background"
          :text_color="header_background[0]"
          :currencyCode="userInfor.currencyCode"
          :shopId="shopId"
          @showOrderSuccess="showOrderSuccess"
        ></ShoppingCart>
      </div>
      <v-menu transition="scale-transition" origin="center center">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            <span>{{ $t("message.contact") }}</span>
            <v-icon>mdi-face-agent</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-btn :href="'tel:' + userInfor.phoneNumber">
              <v-icon>mdi-phone-plus</v-icon>
              <span class="ml-2">{{ userInfor.phoneNumber }}</span>
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn :href="'sms:' + userInfor.phoneNumber">
              <v-icon>mdi-email-outline</v-icon>
              <span class="ml-2">{{ userInfor.phoneNumber }}</span>
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn
              :href="'http://zalo.me/' + userInfor.phoneNumber"
              class="pl-2"
            >
              <v-img :src="zaloIcon" width="40"></v-img>
              <span>{{ userInfor.phoneNumber }}</span>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn href="https://www.youtube.com/channel/UCJlQtYho_GZibMq8TL7pDPg">
        <span>{{ $t("message.guideline") }}</span>
        <v-icon>mdi-youtube</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import mixin from "../mixins/LayoutMixin.js";
import ShoppingCart from "../components/ShoppingCart.vue";
export default {
  name: "LayoutDefault",
  components: { ShoppingCart },
  computed: {
    header_background() {
      return this.stateProduct.backgroundColorLayout;
    },
    text_color() {
      return this.stateProduct.textColorLayout;
    },
  },
  mixins: [mixin],
};
</script>

<style>
@import "../styles/layout.css";
@import "../styles/font.css";
.v-card-custom {
  border-radius: 0px !important;
}
.v-card-custom .v-sheet {
  box-shadow: 0px !important;
}
</style>
