import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Formatter from './formatter'

Vue.use(VueI18n)

const locale = 'vi-VN' // default locale
const formatter = new Formatter({ locale })

export default new VueI18n({
    locale,
    formatter,
    messages: {
        'vi-VN': {
            message: {
                favorite: "Sản phẩm yêu thích",
                otherProduct: "Sản phẩm khác",
                seeAll: "Xem tất cả",
                shoppingCart: "Giỏ hàng",
                orderInfo: "Thông tin đơn hàng",
                product: "Sản phẩm",
                unitPrice: "Giá tiền",
                quantity: "Số lượng",
                totalPrice: "Thành tiền",
                total: "Tổng tiền",
                qty: "SL",
                customerInfo: "Thông tin khách hàng",
                name: "Họ và tên",
                phoneNumber: "Số điện thoại",
                address: "Địa chỉ",
                paymentType: "Hình thức thanh toán",
                cash: "Tiền mặt",
                tranfer: "Chuyển khoản",
                eWallet: "Ví điện tử",
                receivingType: "Hình thức nhận hàng",
                homeDelivery: "Giao tại nhà",
                shopDelivery: "Nhận tại cửa hàng",
                exit: "Thoát",
                order: "Đặt hàng",
                confirm: "Xác nhận",
                orderSuccess: "Đặt hàng thành công",
                orderFailure: "Đặt hàng thất bại",
                nameRequired: "Họ và tên bắt buộc nhập",
                phoneRequired: "Số điện thoại bắt buộc nhập",
                addressRequired: "Địa chỉ bắt buộc nhập",
                phoneNotValid: "Số điện thoại không hợp lệ",
                confirmOrder: "Xác nhận đặt hàng",
                cancel: "Hủy",
                OK: "Đồng ý",
                home: "Trang chủ",
                addProduct: "Đã thêm {0} {1} vào giỏ hàng",
                searchResult: "Kết quả tìm kiếm",
                searchInfo: "Nhập tên sản phẩm và chọn tìm kiếm",
                purchaseOrder: "Giỏ hàng",
                contact: "Liên hệ",
                guideline: "Hướng dẫn",
                productInfo: "Thông tin sản phẩm",
                addToShoppingCart: "Thêm vào giỏ",
                share: "Chia sẻ",
                warningOrder: "Vui lòng thêm sản phẩm vào giỏ hàng",
                orderBy: "Sắp xếp:",
                priceUp: "Giá tăng dần",
                priceDown: "Giá giảm dần",
                nameDown: "Tên A → Z",
                nameUp: "Tên Z → A",
                newProduct: "Mới nhất",
                note: "Ghi chú",
                like: "Yêu thích",
                addToCart: "Thêm vào giỏ",
                searchInfoMobile: "Tìm kiếm sản phẩm",
                language: "Ngôn ngữ"
            }
        },
        'en-US': {
            message: {
                favorite: "Favorite products",
                otherProduct: "Other products",
                seeAll: "See all",
                shoppingCart: "Shopping cart",
                orderInfo: "Order information",
                product: "Product",
                unitPrice: "Unit price",
                quantity: "Quantity",
                totalPrice: "Total price",
                total: "Total",
                qty: "qty",
                customerInfo: "Customer information",
                name: "Name",
                phoneNumber: "Phone number",
                address: "Address",
                paymentType: "Payment type",
                receivingType: "Delivery method",
                cash: "Cash",
                tranfer: "Tranfer",
                eWallet: "Wallet",
                homeDelivery: "Delivery to door",
                shopDelivery: "Pick-up",
                exit: "Close",
                order: "Order",
                confirm: "Confirm",
                orderSuccess: "Order success",
                orderFailure: "Order failed",
                nameRequired: "Name is required",
                phoneRequired: "Phone number is required",
                addressRequired: "Address is required",
                phoneNotValid: "Invalid phone number",
                confirmOrder: "Confirm order",
                cancel: "cancel",
                OK: "OK",
                home: "Home",
                addProduct: "Added {0} {1} to shopping cart",
                searchResult: "Search result",
                searchInfo: "Enter the product name and select search",
                purchaseOrder: "Shopping Cart",
                contact: "Contact",
                guideline: "Guideline",
                productInfo: "Product information",
                addToShoppingCart: "Add to shopping cart",
                share: "Share",
                warningOrder: "Shopping cart is empty",
                orderBy: "Sort by:",
                priceUp: "Price low to hight",
                priceDown: "Price hight to low",
                nameDown: "Name A → Z",
                nameUp: "Name Z → A",
                newProduct: "Latest",
                note: "Note",
                like: "Favorite",
                addToCart: "Add to cart",
                searchInfoMobile: "Search for products",
                language: "Language"
            }
        },
        'ja-JP': {
            message: {
                favorite: "Favorite products",
                otherProduct: "Other products",
                seeAll: "See all",
                shoppingCart: "Shopping cart",
                orderInfo: "Order infomation",
                product: "Product",
                unitPrice: "Unit price",
                quantity: "Quantity",
                totalPrice: "Total price",
                total: "Total",
                qty: "qty",
                customerInfo: "Customer infomation",
                name: "Name",
                phoneNumber: "Phone number",
                address: "Address",
                paymentType: "Payment type",
                receivingType: "Receiving type",
                cash: "Cash",
                tranfer: "Tranfer",
                eWallet: "Wallet",
                homeDelivery: "Home delivery",
                shopDelivery: "Store delivery",
                exit: "Close",
                order: "Order",
                confirm: "Confirm",
                orderSuccess: "Order success",
                orderFailure: "Order failure ",
                nameRequired: "Name is required",
                phoneRequired: "Phone number is required",
                addressRequired: "Address is required",
                phoneNotValid: "Phone number is not valid",
                confirmOrder: "Confirm order",
                cancel: "cancel",
                OK: "OK",
                home: "Home",
                addProduct: "Added {0} {1} to shopping cart",
                searchResult: "Search result",
                searchInfo: "Enter the product name and select search",
                purchaseOrder: "Purchase order",
                contact: "Contact",
                guideline: "Guideline",
                productInfo: "Product information",
                addToShoppingCart: "Add to shopping cart",
                share: "Share",
                warningOrder: "Shopping cart is empty",
                orderBy: "Sort by:",
                priceUp: "Price low → hight",
                priceDown: "Price hight → low",
                nameDown: "Name A → Z",
                nameUp: "Name Z → A",
                newProduct: "Latest",
                note: "Note",
                like: "Favorite",
                addToCart: "Add to cart",
                searchInfoMobile: "Search for products",
                language: "Language"
            }
        }
    }
})