<template>
  <div class="layout-1">
    <!-- Website layout -->
    <div v-if="!$vuetify.breakpoint.xs" class="text-font-layout1">
      <v-app-bar
        app
        :height="115"
        :style="{ background: header_down_background }"
        class="app-bar-layout1"
      >
        <div class="header-layout1">
          <div :style="{ background: backgroundColor, color: text_color }">
            <div class="banner-layout d-flex flex-row top-header">
              <div class="mt-auto mb-auto">
                <span class="ml-2"
                  >{{ $t("message.contact") }}:
                  {{ userInfor.phoneNumber }}</span
                >
              </div>
              <div class="ml-auto">
                <div style="width: 45%" class="float-right">
                  <v-select
                    :items="items"
                    v-model="lang"
                    item-value="id"
                    item-text="name"
                    class="custom"
                    :color="text_color"
                    @change="changeLanguage()"
                  >
                    <template v-slot:selection="{ item }">
                      <img :src="item.image" width="20" class="mr-3" />
                      <span :style="{ color: text_color }">{{
                        item.name
                      }}</span>
                    </template>
                    <template v-slot:item="{ item }">
                      <img :src="item.image" width="20" class="mr-3" />
                      {{ item.name }}
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
          <div class="banner-layout d-flex flex-row bottom-header">
            <v-img
              alt="Shop Icon"
              class="shrink mr-2 mt-auto mb-auto"
              contain
              src="../assets/shopicon2.png"
              transition="scale-transition"
              width="55"
            />
            <div class="mt-auto mb-auto" :style="{ color: text_color }">
              <h4 class="ml-1">
                {{
                  userInfor.name.toUpperCase().length > 56
                    ? userInfor.name.toUpperCase().substring(0, 56) + "..."
                    : userInfor.name.toUpperCase()
                }}
              </h4>
            </div>
            <div
              class="mt-auto mb-auto ml-auto search-product"
              :style="{ width: '55%' }"
            >
              <v-text-field
                dark
                flat
                solo-inverted
                hide-details
                clear-icon="mdi-close-circle-outline"
                class="v-text-custom"
                v-model="productName"
                :style="{ border: '1px solid ' + text_color }"
              >
                <template v-slot:label>
                  <div :style="{ color: text_color }">
                    {{ $t("message.searchInfo") }}
                  </div>
                </template>
                <template v-slot:append>
                  <v-btn
                    @click="searchProduct"
                    tile
                    depressed
                    :color="text_color"
                    class="ma-0"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </div>
            <div class="mt-auto mb-auto ml-4 mr-4">
              <ShoppingCart
                :currencyCodeUserInfo="userInfor.currencyCode"
                :header_background="header_background"
                :text_color="text_color"
                :shopId="shopId"
                @showOrderSuccess="showOrderSuccess"
              ></ShoppingCart>
            </div>
          </div>
        </div>
      </v-app-bar>
      <v-main class="pt-0">
        <router-view></router-view>
      </v-main>
      <v-footer padless>
        <v-card class="flex" flat tile>
          <div
            :style="{
              background: backgroundColor,
              opacity: '.4',
              height: '120px',
            }"
          ></div>
          <div class="footer-text" :style="{ color: text_color }">
            <v-card-title class="mr-auto footer-content pl-2">
              <div class="mt-auto mb-auto footer-content">
                <h3 class="d-flex flex-row">
                  <v-img
                    alt="Shop Icon"
                    class="shrink mr-2 mt-auto mb-auto"
                    contain
                    src="../assets/shopicon2.png"
                    transition="scale-transition"
                    width="55"
                  />
                  <div class="mt-auto mb-auto">
                    {{ userInfor.name.toUpperCase() }}
                  </div>
                </h3>
                <h4 class="mt-3">
                  {{ userInfor.address }}
                </h4>
              </div>
              <v-spacer></v-spacer>
            </v-card-title>
          </div>
          <div :style="{ background: backgroundColor, color: text_color }">
            <v-card-text
              class="py-2 text-start footer-end"
              :style="{ background: backgroundColor }"
            >
              <div class="banner-layout">
                <a
                  href="https://www.netstars.vn/"
                  target="_blank"
                  class="black--text"
                >
                  <h4 :style="{ color: text_color, 'font-size': '0.75rem' }">
                    Copyright {{ new Date().getFullYear() }} | by Netstars Việt
                    Nam
                    <v-icon :color="text_color"
                      >mdi-arrow-right-bold-circle</v-icon
                    >
                  </h4></a
                >
              </div>
            </v-card-text>
          </div>
        </v-card>
      </v-footer>
    </div>
    <!-- Mobile layout -->
    <div
      v-if="$vuetify.breakpoint.xs"
      class="text-font-layout1"
      :style="{ color: text_color }"
    >
      <div
        class="moblie-header-bg"
        :style="{ background: backgroundColor }"
      ></div>
      <div class="moblie-header">
        <div class="d-flex flex-row pt-2 pl-4">
          <v-img
            alt="Shop Icon"
            class="shrink mr-2 mt-auto mb-auto"
            contain
            src="../assets/shopicon2.png"
            transition="scale-transition"
            width="55"
          />
          <h3 class="ml-1 mt-auto mb-auto">
            {{
              userInfor.name.toUpperCase().length > 56
                ? userInfor.name.toUpperCase().substring(0, 56) + "..."
                : userInfor.name.toUpperCase()
            }}
          </h3>
        </div>
        <div
          class="search-product d-flex ml-auto mr-auto mt-3"
          :style="{ width: '90%' }"
        >
          <v-text-field
            dark
            flat
            solo-inverted
            hide-details
            clear-icon="mdi-close-circle-outline"
            :color="text_color"
            class="v-text-custom"
            v-model="productName"
          >
            <template v-slot:label>
              <div :style="{ color: text_color }">
                {{ $t("message.searchInfoMobile") }}
              </div>
            </template>
            <template v-slot:append>
              <v-btn
                @click="searchProduct"
                tile
                depressed
                :color="text_color"
                class="ma-0"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </div>
      </div>
      <v-main class="mt-5">
        <router-view></router-view>
      </v-main>
      <v-footer padless class="mb-14">
        <v-card class="flex" flat tile>
          <div
            :style="{
              background: backgroundColor,
              opacity: '.6',
              height: '120px',
            }"
          ></div>
          <div class="footer-text" :style="{ color: text_color }">
            <v-card-title class="mr-auto footer-content pl-2">
              <div class="mt-auto mb-auto footer-content">
                <h3 class="d-flex flex-row">
                  <v-img
                    alt="Shop Icon"
                    class="shrink mr-2 mt-auto mb-auto"
                    contain
                    src="../assets/shopicon2.png"
                    transition="scale-transition"
                    width="55"
                    style=""
                  />
                  <div class="mt-auto mb-auto">
                    {{ userInfor.name.toUpperCase() }}
                  </div>
                </h3>
                <h4 class="mt-3">
                  {{ userInfor.address }}
                </h4>
              </div>
              <v-spacer></v-spacer>
            </v-card-title>
          </div>
          <div :style="{ background: backgroundColor, color: text_color }">
            <v-card-text
              class="py-2 text-start footer-end pl-2"
              :style="{ background: backgroundColor }"
            >
              <div class="banner-layout">
                <a
                  href="https://www.netstars.vn/"
                  target="_blank"
                  class="black--text"
                >
                  <h4 :style="{ color: text_color, 'font-size': '0.75rem' }">
                    Copyright {{ new Date().getFullYear() }} | by Netstars Việt
                    Nam
                    <v-icon :color="text_color"
                      >mdi-arrow-right-bold-circle</v-icon
                    >
                  </h4></a
                >
              </div>
            </v-card-text>
          </div>
        </v-card>
      </v-footer>
      <v-bottom-navigation
        :color="header_background[0]"
        class="footer nav-font"
        v-show="$vuetify.breakpoint.xs"
        grow
      >
        <div>
          <ShoppingCart
            :text_color="text_color"
            :header_background="header_background"
            :currencyCodeUserInfo="userInfor.currencyCode"
            :titleColor="backgroundColor"
            :buttonColor="button_color"
            :shopId="shopId"
            @showOrderSuccess="showOrderSuccess"
          ></ShoppingCart>
        </div>
        <v-menu transition="scale-transition" origin="center center">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <span>{{ $t("message.contact") }}</span>
              <v-icon>mdi-face-agent</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn :href="'tel:' + userInfor.phoneNumber">
                <v-icon>mdi-phone-plus</v-icon>
                <span class="ml-2">{{ userInfor.phoneNumber }}</span>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn :href="'sms:' + userInfor.phoneNumber">
                <v-icon>mdi-email-outline</v-icon>
                <span class="ml-2">{{ userInfor.phoneNumber }}</span>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                :href="'http://zalo.me/' + userInfor.phoneNumber"
                class="pl-2"
              >
                <v-img :src="zaloIcon" width="40"></v-img>
                <span>{{ userInfor.phoneNumber }}</span>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu transition="scale-transition" origin="center center">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <span>{{ $t("message.language") }}</span>
              <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="item in items" :key="item.id">
              <v-btn
                width="143"
                class="d-flex justify-start"
                @click="changeLanguage(item.id)"
              >
                <img :src="item.image" width="20" class="mr-1" />{{ item.name }}
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn href="https://www.youtube.com/channel/UCJlQtYho_GZibMq8TL7pDPg">
          <span>{{ $t("message.guideline") }}</span>
          <v-icon>mdi-play-circle-outline</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </div>
    <!-- Shop notify success -->
    <v-snackbar v-model="snackbar" centered :timeout="timeout" color="green">
      <h4>
        <v-icon color="white" class="white--text">mdi-check</v-icon>
        <span class="ml-2 white--text">{{ text }}</span>
      </h4>
    </v-snackbar>
  </div>
</template>
<script>
import ShoppingCart from "../components/ShoppingCart.vue";
import mixin from "../mixins/LayoutMixin.js";
export default {
  name: "Layout1",
  data: () => ({
    header_down_background: "#FFFFFF",
    bottom_header_background: "#F6E5CC",
    button_color: "amber darken-1 white--text",
    nav_color: "deep-purple",
  }),
  computed: {
    header_background() {
      return this.stateProduct.backgroundColorLayout1;
    },
    text_color() {
      return this.stateProduct.textColorLayout1;
    },
  },
  components: { ShoppingCart },
  mixins: [mixin],
};
</script>
<style>
@import "../styles/layout.css";
.top-header {
  height: 30px !important;
}
.bottom-header {
  height: 85px !important;
}
.header-layout1 {
  width: 100%;
}
.layout-1 .v-toolbar__content {
  align-items: start !important;
  padding: 0px !important;
  box-shadow: 0 10px 30px rgb(9 32 76 / 10%);
}
.text-font-layout1 {
  font-family: "Roboto", sans-serif !important;
  line-height: 1.2;
}
.search-product > .v-input {
  background: white;
}
.v-text-custom .v-label {
  color: #5736cb !important;
}
.moblie-header-bg {
  width: 100%;
  height: 100px;
}
.moblie-header {
  width: 100%;
  position: absolute;
  top: 0;
}
.moblie-header .search-product {
  box-shadow: 0 10px 30px rgb(9 32 76 / 20%);
}
.moblie-header .search-product > .v-input {
  border: 0px;
  background: white;
  border-radius: 5px !important;
}
.moblie-header .search-product {
  border-radius: 5px !important;
}
.nav-font .v-btn__content {
  font-family: "Roboto", sans-serif !important;
  line-height: 1.2;
  font-weight: bold;
}
.theme--dark.v-text-custom.v-input input {
  color: black !important;
}
.footer-text {
  position: absolute;
  top: 0;
  width: 100%;
}
</style>